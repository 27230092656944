import React from "react"

import AppTypography from "core/components/AppTypography"

import { Box, Tooltip } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Opacity, Thermostat } from "@mui/icons-material"

import { styles } from "./styles"
import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { tooltipTextHelper } from "core/helpers"

interface IGraphBaseHeaderProps {
  title: string
  iconCode: string
  tooltipText?: { en: string; el: string }
}

const GraphBaseHeader = ({
  title,
  iconCode,
  tooltipText,
}: IGraphBaseHeaderProps) => {
  const { language } = useSelector((state: RootState) => state.global)
  const icon = () => {
    switch (iconCode) {
      case "thermostat":
        return <Thermostat sx={styles.thermostatIcon} />
      case "humidity":
        return <Opacity sx={styles.thermostatIcon} />
      default:
        return <Thermostat sx={styles.thermostatIcon} />
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.headerLabel}>
          <Box sx={styles.headerTemperature}>
            {icon()}

            <AppTypography
              textTransform="capitalize"
              sx={styles.headerTempLabel}
            >
              {title}
            </AppTypography>
            {/* <Tooltip title={tooltipTextHelper(language, tooltipText)}>
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </Tooltip> */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default GraphBaseHeader
