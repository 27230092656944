import React from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useGetMachineCardQuery } from "store/Machines/machinesSlice"
import { useResolveAlertMutation } from "store/Alerts/alertsSlice"
import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"

type AlertDialogResolveType = {
  open: boolean
  row: any
  setOpen: (value: boolean) => void
}

const AlertDialogResolve = ({ open, row, setOpen }: AlertDialogResolveType) => {
  const { data } = useGetMachineCardQuery({
    machineId: row.alerts_machine_id as string,
  })

  const selectStateSite = (state: RootState) => state.userInfo
  const { email } = useSelector(selectStateSite)

  const machineName = data?.machine_name ?? ""

  const stateLabel = row.alerts_state

  const [resolveAlert] = useResolveAlertMutation()

  const handleResolve = async () => {
    resolveAlert({
      alerts_id: row.alerts_id,
      username: email,
    })

    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box
        sx={{
          padding: "32px 64px 32px 64px",
        }}
      >
        <DialogTitle align="center" id="alert-dialog-title">
          Resolve Alert?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography align="center" variant="h3">
              {machineName}
            </Typography>
            <Typography align="center" variant="body1">
              {stateLabel}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              color: "white",
            }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleResolve} autoFocus>
            Resolve
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AlertDialogResolve
