export const styles = {
  container: {
    height: 25 * 8,
  },
  iconsGraphContainer: {
    height: 27 * 8,
    ml: 3,
    display: "flex",
  },
  iconsContainer: {
    mr: -1,
    display: "flex",
    flexDirection: "column",
    mt: 1,
    height: "85%",
    justifyContent: "space-between",
  },
  graphContainer: {
    mt: 2,
    mr: 2,
    width: "98%",
  },
}
