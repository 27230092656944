import React, { useEffect } from "react"

import { Asset } from "models/interfaces/machine"
import ChartJsAcceleration from "core/components/AppGraphViews/ChartJsAcceleration"
import ChartJsTemperature from "core/components/AppGraphViews/ChartJsTemperature"
import LastUpdate from "core/components/AppCard/BaseCard/subcomponents/LastUpdate"
import { subDays } from "date-fns"
import { RootState } from "store/rootReducer"
import {
  setSelectedAsset,
  usePostGetCbDataSupaQuery,
} from "store/Assets/assetsSlice"

import { useDispatch, useSelector } from "react-redux"
import { useGetMachineCardStatusInfoSupaQuery } from "store/Machines/machinesSlice"
import { useTranslation } from "react-i18next"

import { Box, Divider, Skeleton, Tabs } from "@mui/material"

import AssetTab from "./AssetTab"
import MachineSummaryRow from "../MachineSummaryRow"
import { styles } from "./styles"
import CurrentTemperature from "../CurrentTemperature"
import { transformSupaResponseData } from "core/api/AssetsApi"

const getPastDayData = (twelveHours = 1) => {
  const DAYS_FACTOR = 1
  const toEpoch = new Date().valueOf()
  const fromCorrected = twelveHours * DAYS_FACTOR
  const fromEpoch = subDays(toEpoch, fromCorrected).valueOf()

  return {
    start_time: fromEpoch,
    end_time: toEpoch,
  }
}

const AssetsTabsDetailView = () => {
  const selectStateAssets = (state: RootState) => state.assets

  const machinesState = (state: RootState) => state.machines
  const { machineSelected } = useSelector(machinesState)
  const { machine_id: machineId = "", asset_collection: availableAssets = [] } =
    machineSelected

  const { assetSelected } = useSelector(selectStateAssets)

  const value = React.useRef("0")

  const time = getPastDayData()
  const [rmsParams, setRMSParams] = React.useState<any>(time)
  const [temperatureParams, setTemperatureParams] = React.useState<any>(time)
  const [skip, setSkip] = React.useState<boolean>(true)
  const [tabsIndex, setTabsIndex] = React.useState<number>(0)

  const dispatch = useDispatch()

  const selectedAssetAxis = useSelector(
    (state: RootState) => state.assets.assetAxisSelected,
  )

  const totalNumOfAvailAssets: number = availableAssets.length

  useEffect(() => {
    let defaultSelectedAsset: any = {}
    if (totalNumOfAvailAssets > Number(value.current)) {
      defaultSelectedAsset = availableAssets[parseInt(value.current)]
    } else {
      // eslint-disable-next-line prefer-destructuring
      defaultSelectedAsset = availableAssets[0]
      value.current = "0"
    }

    if (assetSelected.asset_id !== defaultSelectedAsset.asset_id) {
      dispatch(setSelectedAsset(defaultSelectedAsset))
    }

    setRMSParams((prevState: any) => ({
      ...prevState,
      fields: [`rms_${selectedAssetAxis}`],
      tag_values: [defaultSelectedAsset.asset_id],
      tags: ["asset_id"],
    }))

    setTemperatureParams((prevState: any) => ({
      ...prevState,
      fields: ["temperature"],
      tag_values: [defaultSelectedAsset.asset_id],
      tags: ["asset_id"],
    }))

    setSkip(!defaultSelectedAsset?.asset_id)
  }, [machineSelected, selectedAssetAxis, assetSelected])

  const {
    data: _accelerationData,
    isFetching: isAccelLoading,
    isError: isAccelError,
    fulfilledTimeStamp = undefined,
    refetch,
  } = usePostGetCbDataSupaQuery(
    {
      asset: rmsParams?.tag_values?.[0],
      start_time: rmsParams.start_time,
      end_time: rmsParams.end_time,
    },
    {
      refetchOnMountOrArgChange: true,
      skip,
    },
  )

  const accelerationData = transformSupaResponseData(
    _accelerationData,
    `acc_rms_${selectedAssetAxis}`,
  )

  const {
    data: _temperatureData,
    isError,
    isFetching,
    refetch: refetchTemp,
    fulfilledTimeStamp: temperatureTimestamp,
  } = usePostGetCbDataSupaQuery(
    {
      asset: temperatureParams?.tag_values?.[0],
      start_time: temperatureParams.start_time,
      end_time: temperatureParams.end_time,
    },
    {
      skip,
      refetchOnMountOrArgChange: true,
    },
  )

  const temperatureData = transformSupaResponseData(
    _temperatureData,
    "temperature",
  )

  // TODO : fix this row in the detail view
  const { data: dataInfo, isMachineFetching } =
    useGetMachineCardStatusInfoSupaQuery(
      {
        machineId,
      },
      {
        skip: !machineId,
        selectFromResult: ({ data, isFetching: isFetchingInfo }) => ({
          data,
          isMachineFetching: isFetchingInfo,
        }),
      },
    )

  const {
    state: machineStatus = "",
    duration: machineTimeInCurrentStatus = 0,
  } = dataInfo || {}

  const handleChange = async (
    event: React.SyntheticEvent,
    assetIndex: string,
  ) => {
    value.current = assetIndex
    const newSelectedAsset = availableAssets[parseInt(assetIndex)]

    setTabsIndex(parseInt(assetIndex))

    if (newSelectedAsset) {
      dispatch(setSelectedAsset(newSelectedAsset))
    }
  }

  const { t } = useTranslation()

  const assetsPerMachine = availableAssets.length
  const lastAssetIndex = availableAssets.length - 1
  const isLastUpdateLoading = isFetching || isAccelLoading

  useEffect(() => {
    setTabsIndex(0)
  }, [machineId])

  return (
    <Box sx={styles.root}>
      <MachineSummaryRow
        isLoading={isMachineFetching}
        status={machineStatus}
        numOfMachineAssets={assetsPerMachine}
        timeInStatus={machineTimeInCurrentStatus}
      />
      <Divider sx={{ mt: 4 }} />

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "0.625rem",
          paddingRight: "0.5rem",
        }}
      >
        {isLastUpdateLoading ? (
          <Skeleton variant="text" width={100} sx={{ fontSize: "0.75rem" }} />
        ) : (
          <LastUpdate
            lastUpdate={
              !!fulfilledTimeStamp &&
              !!temperatureTimestamp &&
              fulfilledTimeStamp < temperatureTimestamp
                ? fulfilledTimeStamp
                : temperatureTimestamp
            }
          />
        )}
      </Box>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        sx={styles.tablist}
        value={tabsIndex}
        onChange={handleChange}
      >
        {availableAssets.map(({ asset_id }: Asset, index: number) => (
          <AssetTab
            showDivider={index !== lastAssetIndex}
            key={asset_id}
            assetId={asset_id}
            index={index}
          />
        ))}
      </Tabs>

      <ChartJsAcceleration
        onRetry={refetch}
        axisLabels={{
          yLabel: t("measure.unit.acceleration.label"),
          xLabel: "",
        }}
        accelData={accelerationData}
        isLoading={isAccelLoading}
        hasError={isAccelError}
      />
      <Divider sx={{ my: 3 }} />

      <ChartJsTemperature
        customSubheader={
          <CurrentTemperature assetId={assetSelected.asset_id} />
        }
        isLoading={isFetching}
        onRetry={refetchTemp}
        isError={isError}
        axisLabels={{
          yLabel: "°C",
          xLabel: "",
        }}
        data={temperatureData}
      />
    </Box>
  )
}

export default AssetsTabsDetailView
