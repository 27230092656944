import React from "react"

import { TableCell, Typography } from "@mui/material"
import { Box } from "@mui/system"

import { styles } from "./styles"
import { BORDER_RADIUS, styles as parentStyle } from "../AlertRow/styles"

import {
  selectSiteMachines,
  setSelectedMachine,
  useGetMachineCardQuery,
} from "store/Machines/machinesSlice"
import { AlertDataType } from "../AlertsTab"
import { useGetProductionLineInfoQuery } from "store/Production_lines/productionLineSlice"
import { useGetDepartmentInfoQuery } from "store/Department/departmentSlice"
import { MachineCollection } from "models/organization"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"
import { useNavigate } from "react-router-dom"

type MachineNameCellType = {
  labelId: string
  row: AlertDataType
}

type DepartmentType = {
  department_description: string
  department_id: string
  department_location: string
  department_name: string
  department_timestamp: string
  site_id: string
}

type ProductionLineType = {
  department_id: string
  production_line_description: string
  production_line_id: string
  production_line_location: string
  production_line_name: string
  production_line_timestamp: string
} | null

const MachineNameCell = ({ labelId, row }: MachineNameCellType) => {
  const { data } = useGetMachineCardQuery({
    machineId: row.alerts_machine_id as string,
  })

  const selectOrganizationState = (state: RootState) => state.organizations

  const { organizationId } = useSelector(selectOrganizationState)
  const { organizationData } = useAppGetOrganizationQuery(organizationId)

  const allMachines: MachineCollection[] =
    useSelector((state: RootState) =>
      selectSiteMachines(state, { ...organizationData }),
    ) || []
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const machineName = data?.machine_name ?? ""

  const warningStyle = {
    ...styles.stateLabel,
    backgroundColor: row.alerts_state === "critical" ? "red" : "orange",
  }

  const { data: productionLineData } = useGetProductionLineInfoQuery(
    {
      productionLineId: data?.production_line_id,
    },
    {
      skip: !data?.production_line_id,
    },
  ) as { data: ProductionLineType }

  const { data: departmentData } = useGetDepartmentInfoQuery(
    {
      deptID: productionLineData?.department_id,
    },
    {
      skip: !productionLineData?.department_id,
    },
  ) as { data: DepartmentType }

  const descriptionLabel = `${departmentData?.department_name} - ${productionLineData?.production_line_name}`

  const handleMachineTitleClick = () => {
    const machine = allMachines.find(
      (machineIteration) =>
        machineIteration.machine_id === row.alerts_machine_id,
    )

    dispatch(setSelectedMachine(machine))
    navigate("/machineDetailScreen")
  }

  return (
    <TableCell
      sx={{
        ...parentStyle.tableCell,
        paddingLeft: "16px",
        borderRadius: `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}`,
      }}
      component="th"
      id={labelId}
      scope="row"
      padding="none"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography
            onClick={handleMachineTitleClick}
            sx={{
              ...styles.title,
              color: "primary.main",
              textDecoration: "underline",
              ":hover": {
                cursor: "pointer",
              },
              fontWeight: row.alerts_resolved ? "normal" : "bold",
            }}
          >
            {machineName}
          </Typography>
          <Typography sx={warningStyle}>{row.alerts_state}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={styles.departmentLabel}>
            {descriptionLabel}
          </Typography>
        </Box>
      </Box>
    </TableCell>
  )
}

export default MachineNameCell
