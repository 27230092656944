import * as React from "react"

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import AppMainViewContainer from "core/components/AppMainViewContainer"
import Dashboard from "screens/Dashboard"
import DynamicMachineDetailScreen from "screens/Facilities/subcomponents/Dynamic/DynamicMachineDetailScreen"

import Login from "screens/Login"
import MachineDetailScreen from "screens/Facilities/subcomponents/MachineDetailScreen"
import ProtectedRoute from "../ProtectedRoute"
import { isDynamicUIEnabled } from "core/helpers/dynamic_ui"
import AlertsScreen from "screens/Alerts"

const AppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="login" element={<Login />} />
        <Route element={<AppMainViewContainer />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="machineDetailScreen"
            element={
              <ProtectedRoute>
                <MachineDetailScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="alerts"
            element={
              <ProtectedRoute>
                <AlertsScreen />
              </ProtectedRoute>
            }
          />
          {isDynamicUIEnabled() && (
            <Route
              path="dynamicMachineDetailScreen"
              element={
                <ProtectedRoute>
                  <DynamicMachineDetailScreen />
                </ProtectedRoute>
              }
            />
          )}
        </Route>
        ,
      </>,
    ),
  )

  return <RouterProvider router={router} />
}

export default AppRoutes

export const getLocalStorageScreenNames = (screenName: string): string => {
  const lookup = {
    test: "test",
    AdminLogin: "adminLogin",
    dashboard: "dashboard",
    // facilities: "facilities",
    machineDetailScreen: "facilities",
    overview: "overview",
    assets: "assets",
    alerts: "alerts",
    assetTabView: "assetTabView",
    departments: "departments",
  }

  //@ts-ignore
  return lookup[screenName]
}
