import React from "react"

import StatusIcon from "core/components/StatusIcon"

import { Box } from "@mui/material"

import { TopIcon } from "./TopIcon"

export const TopLedgendIcons = ({ daysPerType }: any) => (
  <Box
    sx={{
      height: 32,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <TopIcon icon={<StatusIcon state="normal" />} days={daysPerType.normal} />
    <TopIcon icon={<StatusIcon state="warning" />} days={daysPerType.warning} />
    <TopIcon
      icon={<StatusIcon state="critical" />}
      days={daysPerType.critical}
    />
    <TopIcon icon={<StatusIcon state="idle" />} days={daysPerType.idle} />
  </Box>
)
