import React from "react"

import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  Theme,
} from "@mui/material"

// import AppCollapseButton from "../AppCollapseButton"
import AppDrawerNavigation from "../AppDrawerNavigation"
import AppDrawerSettings from "../AppDrawerSettings"
import AppHeader from "../AppHeader"
import AppSettings from "../AppSettings"
import { styles } from "./styles"

import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import AppTypography from "../AppTypography"
import { useGetSiteInfoQuery } from "store/Sites/sitesSlice"
import { Outlet } from "react-router-dom"
import Navigator from "core/navigation/Navigator"

const AppMainViewContainer = () => {
  // const selectState = (state: RootState) => state.global
  // const { isDrawerOpen } = useSelector(selectState)
  const { selectedSiteId } = useSelector((state: RootState) => state.sites)

  const { siteName = "" } = useGetSiteInfoQuery(
    { siteUUID: selectedSiteId },
    { selectFromResult: ({ data }) => ({ siteName: data?.site_name }) },
  )

  // const drawerStyles = (theme: Theme) =>
  //   isDrawerOpen ? styles.drawerOpen(theme) : styles.drawerClose(theme)

  return (
    <Box sx={styles.root}>
      {/* <AppCollapseButton /> */}
      <CssBaseline />

      <AppBar sx={styles.appBar}>
        {/*
         * This Toolbar is used to add a shadow to the AppBar when the drawer is open/close
         * and to display the title based on the UI design requirements.
         */}
        <Toolbar
          disableGutters
          sx={(theme: Theme) => ({
            ...styles.appBarShadow,
            ...styles.drawerClose(theme),
          })}
        >
          <AppTypography variant="h2" sx={{ zIndex: 1 }}>
            {siteName}
          </AppTypography>
        </Toolbar>
        <AppHeader />
      </AppBar>
      <AppDrawerNavigation />
      <AppDrawerSettings>
        <AppSettings />
      </AppDrawerSettings>

      <Container maxWidth="xl" disableGutters sx={{ mx: 2, mt: 2 }}>
        <Toolbar disableGutters />
        <Outlet />
      </Container>
      <Navigator />
    </Box>
  )
}

export default AppMainViewContainer
