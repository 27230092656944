import { Auth as AWSAuth } from "aws-amplify"
import {
  setIsLogInLoading,
  setIsUserLogged,
  setLoginError,
} from "store/rootSlice"
import { setOrganizationId } from "store/Organisation/organizationSlice"
import { setUserEmail } from "store/UserInfo/userInfoSlice"
import { store } from "store/store"
import { subscribeTokenToTopic } from "libraries/firebaseInit"
import { supabase } from "core/api/supabase"

const addDefaultEmailAddress = (email: string): string =>
  !email.includes("@") ? `${email.trim()}@core-innovation.com` : email.trim()

const getOrganizationId = async () => {
  const { data: organizationId } = await supabase
    .from("organisation")
    .select("organisation_id")
    .single()

  return organizationId?.organisation_id
}

const subscribeToTopicFromSupa = async (orgId?: string) => {
  const organizationId = orgId ?? (await getOrganizationId())

  await subscribeTokenToTopic(organizationId)
}

const getCognitoToken = async () => {
  const username = "demodeth@core-innovation.com"
  const password = "democore2023"

  const user = await AWSAuth.signIn(username, password)

  const token = (await AWSAuth.currentSession()).getIdToken().getJwtToken()
  await localStorage.setItem("token", token)

  return user
}

export const autoSignIn = async () => {
  AWSAuth.configure({ storage: localStorage })
  store.dispatch(setIsLogInLoading(true))

  const session = await supabase.auth.getSession()

  const { error } = session

  const email = session?.data?.session?.user?.email

  if (!error) {
    await subscribeToTopicFromSupa()
    store.dispatch(setUserEmail(email))
  } else store.dispatch(setIsLogInLoading(false))
}

export const signIn = async ({ username, password }: any) => {
  store.dispatch(setLoginError(""))
  AWSAuth.configure({ storage: localStorage })
  store.dispatch(setIsLogInLoading(true))

  sessionStorage.clear()

  const email = addDefaultEmailAddress(username)

  try {
    await supabase.auth.signOut()

    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    })

    if (!!error) throw error

    const organizationId = await getOrganizationId()

    await getCognitoToken()

    await subscribeToTopicFromSupa(organizationId as any)

    await store.dispatch(setOrganizationId(organizationId))

    store.dispatch(setIsUserLogged(true))
    store.dispatch(setIsLogInLoading(false))
    store.dispatch(setUserEmail(email))
  } catch (error) {
    const { message: errorMessage } = error as any
    if (!!errorMessage) {
      store.dispatch(setLoginError(errorMessage))
    }

    store.dispatch(setIsUserLogged(false))
    store.dispatch(setIsLogInLoading(false))

    return error
  }
}
