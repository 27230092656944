import React from "react"

import Card from "@mui/material/Card"
import { TableRow, TableCell } from "@mui/material"
import MachineNameCell from "../MachineNameCell"

import { AlertDataType } from "../AlertsTab"
import { useAssetInfoQuery } from "store/Assets/assetsSlice"
import { format } from "date-fns"
import ResolveButton from "../ResolveButton"
import { styles } from "./styles"
import ResolveLabelCell from "../ResolveLabelCell"

type AlertRowType = {
  row: AlertDataType
  labelId: string
}

const AlertRow = ({ row, labelId }: AlertRowType) => {
  const { data: assetData } = useAssetInfoQuery(row.alerts_wc_asset_id)

  const assetName = assetData?.asset_name ?? ""

  const stateLabel = `${assetName} is ${row.alerts_state}`

  const dateTimeFormat = "MMM dd, HH:mm"

  const localOffset = new Date().getTimezoneOffset() * 60000

  const resolvedAtLabel = !!row.alerts_resolved_at
    ? format(
        new Date(row.alerts_resolved_at).getTime() - localOffset,
        dateTimeFormat,
      )
    : "-"

  const rowStyle = {
    ...styles.tableCell,
    fontWeight: row.alerts_resolved ? "normal" : "bold",
  }

  return (
    <TableRow
      sx={styles.root}
      component={Card}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.alerts_id}
    >
      <MachineNameCell labelId={labelId} row={row} />
      <TableCell sx={rowStyle} align="right">
        {stateLabel}
      </TableCell>
      <TableCell sx={rowStyle} align="right">
        {format(
          new Date(new Date(row.created_at).getTime() - localOffset),
          dateTimeFormat,
        )}
      </TableCell>
      <ResolveLabelCell rowStyle={rowStyle} row={row} />
      <TableCell sx={rowStyle} align="right">
        {resolvedAtLabel}
      </TableCell>
      <ResolveButton row={row} />
    </TableRow>
  )
}
export default AlertRow
