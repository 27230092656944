import { IUserInfo } from "types/store"

import { createSlice } from "@reduxjs/toolkit"

const initialState: IUserInfo = {
  email: "",
}

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserEmail: (state, action) => {
      state.email = action.payload
    },
  },
})

export const { setUserEmail } = userInfoSlice.actions

export default userInfoSlice.reducer
