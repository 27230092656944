import React from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import NoDataFound from "core/components/NoDataFound"
import { reformAssetData } from "core/dataActions"
import { RootState } from "store/rootReducer"
import { ROW_HEIGHT } from "core/constants/ui"
import { minutesToMilliseconds, secondsToMilliseconds, toDate } from "date-fns"
import { useAppGetTempQuery } from "core/hooks/apiHooks"
import { useGetTempQuery } from "store/Assets/assetsSlice"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"

import { fontSpecs } from "../../helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import { IDynamicGraphProps } from "types/dynamicGraph"

const labelsScaleFactor = 120

type IDynamicTimeseriesGraphProps = IDynamicGraphProps

const DynamicTimeseriesGraph = ({
  titleCode: title,
  measureUnitCode: measureUnit,
  assetId: selectedAssetId,
  iconCode,
  heightFactor,
  thresholds,
  tooltipText,
  refresh_interval: interval = 5,
}: IDynamicTimeseriesGraphProps) => {
  const { t } = useTranslation()

  const { globalSelectedRange: temperatureGraphSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [window.innerWidth])

  const theme = useTheme()

  const minDateToDisplay = temperatureGraphSelectedRange.fromEpoch
  const maxDateToDisplay = temperatureGraphSelectedRange.toEpoch

  const graphConfig = {
    scales: {
      x: {
        min: minDateToDisplay,
        max: maxDateToDisplay,
        title: {
          display: false,
        },
        type: "time",

        ticks: {
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
        },
      },
      y: {
        ticks: {
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        title: {
          font: fontSpecs,
          text: "°C",
        },
      },
    },
  } as ChartOptions<"line">

  const {
    temperatureData = [],
    isFetching,
    isError,
    refetch,
  } = useAppGetTempQuery(
    {
      assetId: selectedAssetId,
      start_time: temperatureGraphSelectedRange.fromEpoch,
      end_time: temperatureGraphSelectedRange.toEpoch,
    },
    {
      skip: !selectedAssetId,
      pollingInterval: minutesToMilliseconds(interval),
    },
  )

  const handleOnRetry = () => refetch()
  const lastIndex = temperatureData.length - 1

  const { latestValue } = useGetTempQuery(
    { selectedAssetId },
    {
      skip: temperatureData.length !== 0 && isFetching,
      selectFromResult: ({ data }) => ({ latestValue: data?.values[0]?.value }),
    },
  )

  const height = ROW_HEIGHT * heightFactor

  if (temperatureData.length === 0) {
    return (
      <BaseCard
        sx={styles.baseCard}
        customHeader={
          <GraphBaseHeader
            title={t(title)}
            iconCode={iconCode}
            tooltipText={tooltipText}
          />
        }
      >
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          onRetry={handleOnRetry}
        >
          <NoDataFound
            style={{
              height: height - 30,
            }}
          />
        </AppCardLoader>
      </BaseCard>
    )
  }

  return (
    <BaseCard
      sx={{ ...styles.baseCard, height }}
      customHeader={
        <GraphBaseHeader
          title={title}
          iconCode={iconCode}
          tooltipText={tooltipText}
        />
      }
    >
      <Box sx={{ ...styles.graphContainer, height: height - 50 }}>
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRetry={() => {}}
        >
          <LineChart
            datasetLabel={title}
            unit={measureUnit}
            zoomEnabled={false}
            config={graphConfig}
            upperBoundOffset={10}
            lineColor={theme.palette.primary.main}
            graphData={reformAssetData(temperatureData)}
            thresholds={thresholds}
          />
        </AppCardLoader>
      </Box>
    </BaseCard>
  )
}

export default DynamicTimeseriesGraph
