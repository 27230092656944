import React, { BaseSyntheticEvent, useEffect, useState } from "react"

import Copyright from "core/components/Copyright"

import { RootState } from "store/rootReducer"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Paper,
  TextField,
  Theme,
} from "@mui/material"

import ErrorMessage from "./subcomponents/ErrorMessage"
import { isFormValid, validateField, validateForm } from "./validation"
import Logo from "./subcomponents/Logo"
import ShowPasswordIcon from "./subcomponents/ShowPasswordIcon"
import { styles } from "./styles"
import WaitLoadingScreen from "./subcomponents/WaitLoadingScreen"
import { useNavigate } from "react-router-dom"
import { signIn } from "core/auth/signIn"

let navigate: any

const Login = () => {
  const { t } = useTranslation()

  const [showPassword, setShowPassword] = useState(false)
  const [isFormSubmitting, setIsFormSubmitting] = useState(false)
  const [touchedFields, setTouchedFields] = useState({} as any)

  const selectStateGlobal = (state: RootState) => state.global
  const { isLogInLoading } = useSelector(selectStateGlobal)

  const [formState, setFormState] = useState({
    username: "",
    password: "",
  } as any)
  const [errors, setErrors] = useState({} as any)
  const isValid = isFormValid(formState)

  const handleOnClickRevealPass = () => setShowPassword(!showPassword)
  navigate = useNavigate()

  const checkField = async (data: any) => {
    const { field } = data
    if (touchedFields[field]) {
      try {
        await validateField(data)
        const updatedErrors = { ...errors }
        delete updatedErrors[`${field}`]
        setErrors(updatedErrors)
      } catch (error: any) {
        setErrors({ ...errors, [error.path]: error.errors })
      }
    }
  }

  const handleOnChange = async (e: BaseSyntheticEvent) => {
    const { name, value } = e.target
    setFormState({ ...formState, [name]: value })
  }

  const handleOnLogin = async (e: any) => {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      await validateForm(formState)
      setErrors({})

      await signIn(formState)

      navigate("/dashboard", { replace: true })
    } catch (error: any) {
      setErrors({ [error.path]: error.errors })
    }

    setIsFormSubmitting(false)
  }

  const handleFieldTouch = (e: BaseSyntheticEvent) => {
    const { name } = e.target
    if (touchedFields[name]) return
    setTouchedFields({ ...touchedFields, [name]: true })
  }

  const getHelperText = (helperText: any) => !!helperText && t(helperText)

  useEffect(() => {
    checkField({ field: "username", value: formState.username })
  }, [formState.username])

  useEffect(() => {
    checkField({ field: "password", value: formState.password })
  }, [formState.password])

  return (
    <Box sx={styles.root}>
      {isLogInLoading ? (
        <WaitLoadingScreen />
      ) : (
        <Paper sx={styles.paperContainer} elevation={3}>
          <ErrorMessage />
          <Logo />
          <form name="form" onSubmit={handleOnLogin} autoComplete="off">
            <FormControl sx={styles.formContainer}>
              <TextField
                InputLabelProps={{ sx: styles.textFieldLabel }}
                InputProps={{
                  sx: { root: styles.inputField },
                }}
                autoFocus={true}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ "data-testid": "username" }}
                onChange={handleOnChange}
                onFocus={handleFieldTouch}
                onBlur={handleFieldTouch}
                label={t("username.text")}
                name="username"
                // type="email"
                error={!!errors.username}
                value={formState.username}
                helperText={getHelperText(errors.username)}
                sx={styles.emailField}
              />
              <TextField
                name="password"
                InputLabelProps={{ sx: styles.textFieldLabel }}
                InputProps={{
                  endAdornment: (
                    <ShowPasswordIcon
                      showPassword={showPassword}
                      onClick={handleOnClickRevealPass}
                    />
                  ),
                  sx: { root: styles.inputField },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ "data-testid": "password" }}
                onChange={handleOnChange}
                onFocus={handleFieldTouch}
                onBlur={handleFieldTouch}
                type={showPassword ? "text" : "password"}
                error={!!errors.password}
                helperText={getHelperText(errors.password)}
                value={formState.password}
                label={t("password.text")}
                style={{
                  marginTop: "8px",
                }}
              />

              <Button
                data-testid="loginButton"
                disabled={!isValid || isFormSubmitting}
                sx={(theme: Theme) => styles.loginButton(theme)}
                type="submit"
                color="primary"
                variant="contained"
              >
                {t("login.text")}
                {isFormSubmitting && (
                  <CircularProgress
                    size={20}
                    sx={(theme: Theme) => styles.circle(theme)}
                  />
                )}
              </Button>
            </FormControl>
          </form>
          <Divider />
          <Box sx={styles.copyright}>
            <Copyright />
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default Login
