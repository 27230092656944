import React from "react"

import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import { RootState } from "store/rootReducer"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"

import { fontSpecs } from "../helpers"
import { styles } from "./styles"

import { Thermostat } from "@mui/icons-material"

import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import AppTypography from "core/components/AppTypography"
import { getTimeFormat } from "core/helpers"
import { usePostGetCbDataSupaQuery } from "store/Assets/assetsSlice"
import { transformSupaResponseData } from "core/api/AssetsApi"
import { renderFlags } from "core/constants/ui"

const labelsScaleFactor = 120

const MachineTemperatureGraph = ({ selectedAssetId: assetId }: any) => {
  const { t } = useTranslation()

  const {
    showThresholds,
    globalGraphUnit,
    globalSelectedRange: temperatureGraphSelectedRange,
  } = useSelector((state: RootState) => state.global)

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [window.innerWidth])

  const theme = useTheme()

  const GRAPH_MIN_DATE = temperatureGraphSelectedRange.fromEpoch ?? null
  const GRAPH_MAX_DATE = temperatureGraphSelectedRange.toEpoch ?? null

  const graphConfig = {
    scales: {
      x: {
        min: GRAPH_MIN_DATE,
        max: GRAPH_MAX_DATE,
        title: {
          display: false,
        },
        time: {
          unit: globalGraphUnit,
        },
        offset: true,
        type: "time",
        ticks: {
          font: {
            size: 10,
          },
          callback: (value: any) => getTimeFormat(value),
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
          maxRotation: 0,
        },
      },
      y: {
        ticks: {
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        title: {
          font: fontSpecs,
          text: "°C",
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    spanGaps: 999999,
  } as ChartOptions<"line">

  const { data, refetch, isFetching, isError } = usePostGetCbDataSupaQuery(
    {
      asset: assetId,
      start_time: temperatureGraphSelectedRange.fromEpoch,
      end_time: temperatureGraphSelectedRange.toEpoch,
    },
    {
      skip: !assetId,
    },
  )

  const handleOnRetry = () => refetch()

  const graphData = transformSupaResponseData(data, "temperature")

  return (
    <AppGraphWrapper
      isLoading={renderFlags.isFirstRenderTemp && isFetching}
      hasError={isError}
      onRetry={handleOnRetry}
      noDataCheck={!graphData.length}
      restHeaderProps={{
        headerLabel: (
          <AppTypography capitalizeFirstLetter variant="h4">
            {t("temperature.text")}
          </AppTypography>
        ),

        preTitleIcon: <Thermostat sx={styles.preIcon} />,
        // afterTitleIcon: <InfoOutlinedIcon sx={styles.afterIcon} />,
      }}
    >
      <LineChart
        datasetLabel={t("temperature.text")}
        zoomEnabled={false}
        config={graphConfig}
        upperBoundOffset={10}
        lineColor={theme.palette.primary.main}
        graphData={graphData}
        thresholds={showThresholds ? { warning: 40, critical: 60 } : null}
      />
    </AppGraphWrapper>
  )
}

export default MachineTemperatureGraph
