import * as React from "react"
import { useContext, useEffect } from "react"

import { capitalizeFistLetter } from "core/helpers"
import { ColorModeContext } from "theme/ThemeProvider"
import { logout } from "core/auth/auth"
import { RootState } from "store/rootReducer"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, ListItem, ListItemText } from "@mui/material"

import { styles } from "./styles"

const LogoutButton = () => {
  const { t } = useTranslation()
  const colorMode = useContext(ColorModeContext)

  const selectState = (state: RootState) => state.global
  const { themeType } = useSelector(selectState)

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  const onMountRender = async () => {
    const savedAppTheme = await localStorage.getItem("themeMode")
    colorMode.setColorMode(savedAppTheme)
  }

  useEffect(() => {
    onMountRender()
  }, [themeType])

  return (
    <Box data-cy="logoutButton" sx={styles.container} onClick={stopPropagation}>
      <ListItem sx={styles.text} onClick={logout}>
        <ListItemText
          sx={styles.label}
          primary={capitalizeFistLetter(t("logout.text"))}
        />
      </ListItem>
    </Box>
  )
}

export default LogoutButton
