import { Theme, lighten } from "@mui/material"

export const styles = {
  container: {
    backgroundColor: "primary.main",
    width: "100%",
    ":hover": {
      backgroundColor: (theme: Theme) =>
        lighten(theme.palette.primary.main, 0.5),
    },
  },
  label: {
    textAlign: "center",
  },
  text: {
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "11.5",
    color: "primary.light",
  },
}
