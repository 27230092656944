export const styles = {
  container: {
    mlr: 2,
    mt: 5,
    display: "flex",
    flexDirection: "column",
    p: 1,
    minHeight: "calc(100vh - 376px)",
  },
  accordionContainer: {
    "::before": {
      opacity: 0,
    },
    boxShadow: "none",
    ".MuiAccordionSummary-root": {
      minHeight: "2rem",
    },
  },
  graphsContainer: { padding: 1, paddingRight: 20 / 8, textAlign: "center" },

  accordionDetails: {
    backgroundColor: "background.default",
    borderRadius: "0.5rem",
    display: "flex",
    justifyItems: "space-between",
    width: "100%",
    my: 1,
    maxHeight: "2rem",
  },
  accordionTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },

  accordionPadding: {
    p: 0,
    width : "92vw",
  },
}
