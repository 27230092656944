import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { setIsSettingsOpen } from "store/rootSlice"

import { Box, Drawer } from "@mui/material"

import { styles } from "./styles"

const AppDrawerSettings = (props: any) => {
  const selectState = (state: RootState) => state.global
  const { isSettingsOpen } = useSelector(selectState)
  const dispatch = useDispatch()

  const handleDrawerClose = () => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    dispatch(setIsSettingsOpen(false))
  }

  return (
    <Drawer
      anchor="right"
      open={isSettingsOpen}
      variant="temporary"
      onClose={handleDrawerClose()}
    >
      <Box
        sx={styles.drawerContainer}
        role="presentation"
        onClick={handleDrawerClose()}
        onKeyDown={handleDrawerClose()}
      >
        {props.children}
      </Box>
    </Drawer>
  )
}

export default AppDrawerSettings
