import BAPI from "../";
import { supabase } from "../supabase";

const MachinesAPI = {
  async getMachineDurationInState(machineID: string) {
    return BAPI.get(`duration_in_state/${machineID}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },

  async getMachineDurationInStateRust(machineID: string) {
    return BAPI.get(`duration_in_state_rs/${machineID}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },

  async getMachineDurationInStateSupa(machineID: string) {
    return await supabase.rpc("get_machine_state_diff_seconds", {
      input_machine_id: machineID,
    })
  },
}

export default MachinesAPI

interface IMachineState {
  machine_id: string
  type?: "health" | "state"
  start_time?: number
  end_time?: number
}
