/* eslint-disable react/jsx-no-useless-fragment */
import { Box } from "@mui/material"
import React from "react"
import { styles } from "./styles"

const ItemIndicator = ({ visible = false }: any) => (
  <>
    {visible ? (
      <Box sx={styles.container}>
        <Box sx={styles.triangle}></Box>
        <Box sx={styles.selected} />
      </Box>
    ) : null}
  </>
)

export default ItemIndicator
