import React, { useEffect } from "react"
import { DateRangePicker } from "rsuite"

import { Box } from "@mui/material"

import { useDispatch } from "react-redux"

import { styles } from "./styles"
import "rsuite/dist/rsuite.min.css"
import { format } from "date-fns"
import { getRanges, predefinedBottomRanges } from "./constants"
import { renderFlags } from "core/constants/ui"

export const DateTimeRangePicker = ({
  selectedActionToDispatch,
  timespan,
}: any) => {
  const dispatch = useDispatch()
  const [pickerValue, setPickerValue] = React.useState([
    new Date(timespan.fromEpoch),
    new Date(timespan.toEpoch),
  ])

  const resetLoader = () => {
    renderFlags.isFirstRenderHealth = true
    renderFlags.isFirstRenderTemp = true
    renderFlags.isFirstRenderRMS = true
  }

  const onOk = (date: any) => {
    resetLoader()
    const payload = {
      fromEpoch: new Date(date[0]).toISOString(),
      toEpoch: new Date(date[1]).toISOString(),
    }

    setPickerValue([new Date(payload.fromEpoch), new Date(payload.toEpoch)])
    dispatch(selectedActionToDispatch(payload))
  }

  const onShortcutClick = (shortcut: any) => {
    resetLoader()

    const { label } = shortcut
    const newRanges = getRanges(label)
    setPickerValue([new Date(newRanges.fromEpoch), new Date(newRanges.toEpoch)])
    dispatch(selectedActionToDispatch(newRanges))
  }

  const getPlaceholder = (): string => {
    if (timespan?.fromEpoch !== undefined) {
      return `${format(
        new Date(timespan.fromEpoch),
        "dd-MMM-yy HH:mm",
      )} <|> ${format(new Date(timespan.toEpoch), "dd-MMM-yy HH:mm")}`
    } else {
      const timespandMilli = timespan * 1000

      return `${format(
        new Date(new Date().getTime() - timespandMilli),
        "dd-MMM-yy HH:mm",
      )} <|> ${format(new Date(), "dd-MMM-yy HH:mm")}`
    }
  }

  const placeholder = getPlaceholder()

  useEffect(() => {
    setPickerValue([new Date(timespan.fromEpoch), new Date(timespan.toEpoch)])
  }, [timespan])

  return (
    <Box sx={styles.formContainer}>
      <DateRangePicker
        ranges={predefinedBottomRanges as any}
        size="lg"
        block
        //@ts-ignore
        value={pickerValue}
        character=" ~ "
        placement="bottomEnd"
        onShortcutClick={onShortcutClick}
        style={{ width: 38 * 8 }}
        preventOverflow
        shouldDisableDate={DateRangePicker.afterToday()}
        onOk={(date) => onOk(date)}
        showMeridian
        placeholder={placeholder}
        appearance="default"
        format="dd-MMM-yy HH:mm"
      />
    </Box>
  )
}
export default DateTimeRangePicker
