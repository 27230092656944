import React from "react"

import AppTypography from "core/components/AppTypography"
import { capitalizeFistLetter } from "core/helpers"

import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"
import { GraphicEq } from "@mui/icons-material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import { styles } from "./styles"
import VelocityType from "./VelocityType"

const GraphBaseHeader = ({ title = "" }: any) => {
  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Box sx={styles.headerContainer}>
          <Box sx={styles.headerLabel}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GraphicEq sx={styles.graphicEq} />
              <AppTypography
                capitalizeFirstLetter
                variant="h6"
                sx={styles.headerTempLabel}
              >
                {t("vibration.text")}
              </AppTypography>

              {/* <InfoOutlinedIcon sx={styles.infoIcon} /> */}
            </Box>
            <AppTypography
              capitalizeFirstLetter
              variant="h6"
              sx={styles.headerTempLabel}
            >
              {title}
            </AppTypography>
          </Box>
        </Box>
        <VelocityType capitalizeFistLetter={capitalizeFistLetter} t={t} />
      </Box>
    </Box>
  )
}

export default GraphBaseHeader
