import React from "react";

import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import { type } from "os";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

type MachineNotFoundForDynamicUIProps = {
  selectedMachine: string;
};

export const MachineNotFoundForDynamicUI = ({ selectedMachine } : MachineNotFoundForDynamicUIProps) => {
  const config =  useSelector((state: RootState) => state.ui.configuration)

  const machineListIds =  config.machineDetailScreen.machines.map((machine: any) => machine.id)

  return (
    <Box
      sx={styles.container}
    >
      <Typography sx={styles.typography} variant="h3">{`Selected machine : ${selectedMachine}`}</Typography>
      <Typography sx={styles.typography} variant="h6">Machine not found for dynamic UI. Update the config file to support this selection.</Typography>
      <Typography sx={styles.typography} variant="body1">Supported machines :</Typography>
      {machineListIds.map((machine: any, index : number) => (
        <Typography key={index} sx={styles.typography} variant="body1">{machine}</Typography>
      ))
      }
    </Box>
  );
};
