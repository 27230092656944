import React from "react"

import { RootState } from "store/store"
import { setMachineStatusHistoryDuration } from "store/Machines/machinesSlice"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, Button, ButtonGroup } from "@mui/material"

import { styles } from "./styles"

export function DurationButtons() {
  const dispatch = useDispatch()
  const { machineStatusHistoryDuration } = useSelector(
    (state: RootState) => state.machines,
  )
  const isSelected = (durationSelected: string) =>
    durationSelected === machineStatusHistoryDuration
      ? {
          color: "text.primary",
        }
      : {}

  const { t } = useTranslation()
  const onClick = (e: any) => {
    const duration: string = e.currentTarget.value
    dispatch(setMachineStatusHistoryDuration(duration))
  }

  return (
    <Box sx={styles.durationsContainer}>
      <ButtonGroup
        sx={styles.buttonGroup}
        size="small"
        variant="text"
        aria-label="text button group"
      >
        {/* <Button
          onClick={onClick}
          value="1_week"
          sx={{ color: "text.secondary", fontWeight: "light" }}
        >{`${t("last.week.text")}`}</Button> */}
        <Button
          onClick={onClick}
          value="1_week"
          sx={{ ...styles.buttonDurationContainer, ...isSelected("1_week") }}
        >{`1 ${t("week.text")}`}</Button>
        <Button
          onClick={onClick}
          value="1_month"
          sx={{ ...styles.buttonDurationContainer, ...isSelected("1_month") }}
        >{`1 ${t("month.text")}`}</Button>
        <Button
          onClick={onClick}
          value="12_month"
          sx={{ ...styles.buttonDurationContainer, ...isSelected("12_month") }}
        >
          {`12 ${t("months.text")}`}
        </Button>
      </ButtonGroup>
    </Box>
  )
}
