import { SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import React from "react"

import { styles } from "./styles"

interface SelectedIconProps {
  Component: OverridableComponent<
    SvgIconTypeMap<Record<string, unknown>, "svg">
  >

  isActive: boolean
}

const SelectedIcon = ({
  Component,

  isActive,
}: SelectedIconProps) => (
  <Component
    sx={{
      ...styles.icon,
      ...(isActive && styles.iconSelected),
    }}
  />
)

export default SelectedIcon
