import AppTypography from "core/components/AppTypography"
import { format } from "date-fns"
import { t } from "i18next"
import React from "react"

interface ILastUpdate {
  lastUpdate: number | undefined | null
}

const LastUpdate = ({ lastUpdate }: ILastUpdate) => (
  <>
    {!!lastUpdate && (
      <AppTypography
        capitalizeFirstLetter
        typographyProps={{
          fontSize: "0.75rem",
          color: "text.secondary",
        }}
      >
        {`${t("last.update.text")}: 
${format(new Date(lastUpdate), "dd/MM, HH:mm")}`}
      </AppTypography>
    )}
  </>
)

export default LastUpdate
