import React from "react"

import AppTypography from "core/components/AppTypography"
import { DefaultTFuncReturn } from "i18next"

import { Box, Tooltip, useTheme } from "@mui/material"

import { styles } from "./styles"

export interface IConditionStatusInfo {
  info?: string | React.ReactNode
  icon?: any
  sx?: any
  status?: string
  title?: string | DefaultTFuncReturn
  tooltip?: string
}

// eslint-disable-next-line max-len
const ConditionStatusInformation = ({
  icon,
  info,
  sx,
  title,
  status = "",
  tooltip = "",
}: IConditionStatusInfo) => {
  const isArray = Array.isArray(info)
  const theme = useTheme()
  // eslint-disable-next-line no-shadow
  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "critical":
        return theme.palette.error.main
      case "warning":
        return theme.palette.warning.main
      case "normal":
        return theme.palette.success.main

      default:
        return theme.palette.text.secondary
    }
  }

  return (
    <Tooltip title={!!tooltip ? tooltip : ""}>
      <Box sx={{ ...styles.boxContainers, ...sx }}>
        {!!title ? (
          <AppTypography
            variant="subtitle1"
            sx={styles.title}
            capitalizeFirstLetter
          >
            {title}
          </AppTypography>
        ) : null}
        <Box sx={styles.container}>
          <Box
            sx={{
              ...styles.icons,
              color: getStatusColor(status),
            }}
          >
            {icon}
          </Box>
          {isArray ? (
            <Box data-testid="locationDescription" sx={styles.textContainer}>
              {info.map((item: any, index: number) => (
                <AppTypography key={index} sx={{ ...styles.locationText }}>
                  {item}
                </AppTypography>
              ))}
            </Box>
          ) : (
            <AppTypography variant="h5" sx={styles.text}>
              {typeof info === "string" ? info : <div>{info}</div>}
            </AppTypography>
          )}
        </Box>
      </Box>
    </Tooltip>
  )
}

export default ConditionStatusInformation
