import React from "react"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material"

import { RootState } from "../../../store/rootReducer"
import AppTypography from "../AppTypography"

import { styles } from "./styles"
import SelectedIcon from "./subcomponents/SelectedIcon"
import { NavLink } from "react-router-dom"
import { exportCategoryTitles } from "./constants/categoryTitles"
import { useGetAlertsQuery } from "store/Alerts/alertsSlice"
import { AlertDataType } from "screens/Alerts/subcomponents/AlertsTab"

const AppDrawerNavigation = () => {
  const { t } = useTranslation()

  const selectState = (state: RootState) => state.global
  const { isDrawerOpen } = useSelector(selectState)

  const CATEGORY_TITLES = exportCategoryTitles()

  const selectStateOrg = (state: RootState) => state.organizations
  const { organizationId } = useSelector(selectStateOrg)

  const { data } = useGetAlertsQuery({
    organizationId: organizationId,
  })

  const noOfUnresolvedAlerts = data?.filter(
    (alert: AlertDataType) => alert.alerts_resolved === false,
  ).length

  const noOfAlertsLabel = !!noOfUnresolvedAlerts
    ? noOfUnresolvedAlerts > 10
      ? "9+"
      : noOfUnresolvedAlerts
    : ""

  return (
    <Drawer
      variant="permanent"
      sx={(theme) => ({
        ...styles.drawer,
        ...(isDrawerOpen && {
          ...styles.drawerOpen(theme),
          "& .MuiDrawer-paper": styles.drawerOpen(theme),
        }),
        ...(!isDrawerOpen && {
          ...styles.drawerClose(theme),
          "& .MuiDrawer-paper": styles.drawerClose(theme),
        }),
      })}
    >
      <Toolbar />
      <Box sx={styles.listContainer}>
        <List>
          {CATEGORY_TITLES.map((item, i) => (
            <NavLink
              style={styles.navLink}
              to={item.idsAvailable[0]}
              key={item.idsAvailable[0]}
            >
              {({ isActive }) => (
                <ListItem
                  disablePadding
                  disableGutters
                  data-cy={`MenuItem${i}`}
                  sx={(theme) =>
                    isActive
                      ? {
                          ...styles.listItem(theme),
                          ...styles.listItemSelected(theme),
                        }
                      : styles.listItem(theme)
                  }
                >
                  <ListItemButton disableGutters>
                    <Tooltip
                      title={
                        !isDrawerOpen ? (
                          <AppTypography capitalizeFirstLetter>
                            {t(item.title)}
                          </AppTypography>
                        ) : (
                          ""
                        )
                      }
                      placement="right-start"
                      arrow
                    >
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                        }}
                      >
                        <ListItemIcon sx={styles.ListIcon}>
                          <SelectedIcon
                            Component={item.icon}
                            isActive={isActive}
                          />
                        </ListItemIcon>
                        {item.title === "category.alerts" &&
                          !!noOfAlertsLabel && (
                            <Typography
                              sx={{
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                bottom: "-4px",
                                right: "0",
                                color: "white",
                                width: "20px",
                                height: "20px",
                                fontSize: "12px",
                                borderRadius: "50%",
                                borderWidth: "1px",
                                borderColor: "white",
                                borderStyle: "solid",
                                backgroundColor: "red",
                                fontWeight: "bold",
                              }}
                            >
                              {noOfAlertsLabel}
                            </Typography>
                          )}
                      </Box>
                    </Tooltip>

                    <Zoom
                      in={isDrawerOpen}
                      style={{
                        transitionDelay: isDrawerOpen ? "100ms" : "0ms",
                      }}
                    >
                      <ListItemText
                        sx={styles.listItemText}
                        primary={t(item.title)}
                      />
                    </Zoom>
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default AppDrawerNavigation
