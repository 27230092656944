import { Box, Tooltip } from "@mui/material"
import AppTypography from "core/components/AppTypography"

import React from "react"
import { useTranslation } from "react-i18next"
import { styles } from "./styles"

import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
interface SpecItemProps {
  label: string
  text: any
}
const SpecItem = ({ label, text }: SpecItemProps) => {
  const { t } = useTranslation()
  const showIcon = label === "machineType"
  return (
    <Box sx={styles.specItemContainer}>
      <Box sx={{ display: "flex" }}>
        <AppTypography variant="body2" sx={styles.label} capitalizeFirstLetter>
          {t(label)}:
        </AppTypography>
        <AppTypography variant="body2" sx={styles.text}>
          {text}
        </AppTypography>
      </Box>
      {showIcon && (
        <Tooltip
          placement="left"
          arrow
          title={<AppTypography>{t("edit.text")}</AppTypography>}
        >
          <EditOutlinedIcon
            sx={{ height: "16px", width: "16px" }}
            color="primary"
          />
        </Tooltip>
      )}
    </Box>
  )
}

export default SpecItem
