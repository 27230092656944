import React from "react"

const CircleComponent = ({
  center,
  radius,
  circumference,
  item,
  strokeWidth = 6,
  color = "#E2E2E2",
  rotate = 0,
}: any) => (
  <circle
    aria-label="circle"
    style={{ transform: `rotate(${rotate}deg)`, transformOrigin: "center" }}
    cx={center.x}
    cy={center.y}
    r={radius}
    strokeDasharray={!!circumference ? circumference : undefined}
    strokeDashoffset={
      !!circumference ? circumference - item.strokeDashoffset : undefined
    }
    strokeWidth={strokeWidth}
    fill="none"
    stroke={!!item ? item.color : color}
  />
)

export default CircleComponent
