import "react-toastify/dist/ReactToastify.css"

import React, { useEffect, useState } from "react"

import AppTypography from "core/components/AppTypography"
import { DefaultTFuncReturn } from "i18next"
import PropTypes from "prop-types"

import { Box } from "@mui/material"

import { styles } from "./styles"
import { RootState } from "store/rootReducer"
import { useSelector } from "react-redux"
import { useGetAlertsQuery } from "store/Alerts/alertsSlice"

export interface IReactNotificationComponent {
  title: string | DefaultTFuncReturn
  body: string
  image?: string
}

const ReactNotificationComponent = ({
  title,
  body,
  image,
}: IReactNotificationComponent) => {
  const hideNotif = title === ""
  const [show, setShow] = useState(hideNotif)

  const selectState = (state: RootState) => state.organizations
  const { organizationId } = useSelector(selectState)

  const { refetch: refetchAlerts } = useGetAlertsQuery({ organizationId })

  useEffect(function () {
    refetchAlerts()
    return () => {
      setShow(false)
    }
  }, [])

  return (
    <>
      {!show ? (
        <Box sx={styles.container}>
          <Box sx={styles.titleContainer}>
            <AppTypography variant="h6" sx={styles.title}>
              {title}
            </AppTypography>
          </Box>
          <Box sx={styles.bodyContainer}>
            <AppTypography sx={styles.body}>{body}</AppTypography>
          </Box>
          {!!image && (
            <Box
              component="img"
              sx={styles.image}
              src={image}
              alt="notification-img"
            />
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}

ReactNotificationComponent.defaultProps = {
  title: "This is title",
  body: "Some body",
}

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default ReactNotificationComponent
