import React, { useEffect } from "react"

import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"

import { RootState } from "store/rootReducer"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"

import { fontSpecs } from "../helpers"

import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import SimpleHeader from "../MachineDetailScreen/subcomponents/SimpleHeader"
import { getTimeFormat } from "core/helpers"
import { FieldOptions } from "types/assetsApi"
import { usePostGetCbDataSupaQuery } from "store/Assets/assetsSlice"
import { measurementType, transformSupaResponseData } from "core/api/AssetsApi"

import { store } from "store/store"
import { DEMO_RIG_MACHINE_ID, renderFlags } from "core/constants/ui"
import { setGlobalSelectedRange } from "store/rootSlice"

const labelsScaleFactor = 120

const MachineVibrationGraph = ({
  selectedAssetId: assetId,
  title = "",
}: any) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { machineSelected } = useSelector((state: RootState) => state.machines)

  const {
    // showThresholds,
    globalGraphUnit,
    globalSelectedRange: vibrationGraphSelectedRange,
  } = useSelector((state: RootState) => state.global)

  const { assetAxisSelected, assetTypeVelocity } = useSelector(
    (state: RootState) => state.assets,
  )

  const isAcceleration = assetTypeVelocity === "acceleration"

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [[window.innerWidth]])

  const GRAPH_MIN_DATE = vibrationGraphSelectedRange.fromEpoch ?? null
  const GRAPH_MAX_DATE = vibrationGraphSelectedRange.toEpoch ?? null

  const graphConfig = {
    scales: {
      x: {
        min: GRAPH_MIN_DATE,
        max: GRAPH_MAX_DATE,

        title: {
          display: false,
        },
        offset: true,
        type: "time",
        time: {
          unit: globalGraphUnit,
        },
        ticks: {
          source: "auto",
          font: {
            size: 10,
          },
          callback: (value: any) => getTimeFormat(value),
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
          maxRotation: 0,
        },
      },
      y: {
        ticks: {
          font: {
            size: 10,
          },
          maxTicksLimit: 6,
        },
        title: {
          font: fontSpecs,
          text: isAcceleration
            ? t("measure.unit.acceleration.label")
            : t("measure.unit.velocity.label"),
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    spanGaps: 999999,
  } as ChartOptions<"line">

  const dataSelector: FieldOptions = isAcceleration
    ? `rms_${assetAxisSelected}`
    : `velocity_${assetAxisSelected}`

  const { data, refetch, isFetching, isError } = usePostGetCbDataSupaQuery(
    {
      asset: assetId,
      start_time: vibrationGraphSelectedRange.fromEpoch,
      end_time: vibrationGraphSelectedRange.toEpoch,
    },
    {
      skip: !assetId,
    },
  )

  let dataType: measurementType

  switch (dataSelector) {
    case "rms_x":
      dataType = "acc_rms_x"
      break
    case "rms_y":
      dataType = "acc_rms_y"
      break
    case "rms_z":
      dataType = "acc_rms_z"
      break
    case "velocity_x":
      dataType = "vel_rms_x"
      break
    case "velocity_y":
      dataType = "vel_rms_y"
      break
    case "velocity_z":
      dataType = "vel_rms_z"
      break
    default:
      dataType = "acc_rms_x"
  }

  const graphData = transformSupaResponseData(data, dataType)

  const dispatch = useDispatch()

  const refetchData = () => {
    const currentRange = store.getState().global.globalSelectedRange

    renderFlags.isFirstRenderRMS = false
    renderFlags.isFirstRenderTemp = false
    const timeDifference =
      new Date().getTime() - new Date(currentRange.toEpoch).getTime()

    const payload = {
      fromEpoch: new Date(
        new Date(currentRange.fromEpoch).getTime() + timeDifference,
      ).toISOString(),
      toEpoch: new Date(
        new Date(currentRange.toEpoch).getTime() + timeDifference,
      ).toISOString(),
    }

    dispatch(setGlobalSelectedRange(payload))
  }

  useEffect(() => {
    if (DEMO_RIG_MACHINE_ID !== machineSelected?.machine_id) return

    const intervalId = setInterval(() => {
      refetchData()
    }, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <AppGraphWrapper
      subheader={<SimpleHeader title={title} />}
      isLoading={renderFlags.isFirstRenderRMS && isFetching}
      hasError={isError}
      onRetry={refetch}
      noDataCheck={!graphData.length}
    >
      <div style={{ height: "85%" }}>
        <LineChart
          datasetLabel={t(isAcceleration ? "acceleration.text" : "velocity")}
          zoomEnabled={false}
          config={graphConfig}
          upperBoundOffset={0.5}
          lineColor={theme.palette.primary.main}
          graphData={graphData}
        />
      </div>
    </AppGraphWrapper>
  )
}

export default MachineVibrationGraph
