import { supabase } from "../supabase";

const DepartmentsAPI = {
  async getDepartmentInfo(deptID: string) {
    return supabase
      .from("department")
      .select("*")
      .eq("department_id", deptID)
      .single()
  },
}
export default DepartmentsAPI
