import { supabase } from "../supabase"

const ProductionLineAPI = {
  async getProductionLineInfo(productionLineId: string) {
    return supabase
      .from("production_line")
      .select("*")
      .eq("production_line_id", productionLineId)
      .single()
  },
}
export default ProductionLineAPI
