import React from "react"

import AppTypography from "core/components/AppTypography"
import { RootState } from "store/rootReducer"
import { setHealthTimespan } from "store/Machines/machinesSlice"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"

import { styles } from "./styles"
import { TopLedgendIcons } from "./TopLedgendIcons"

import DateTimeRangePicker from "core/components/DateTimeRangePicker"

const GraphBaseHeader = ({ daysPerType, isDemoMachine }: any) => {
  const { t } = useTranslation()
  const { healthGraphSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Box sx={styles.headerContainer}>
          <Box sx={styles.headerLabel}>
            <AppTypography variant="h2" sx={styles.headerTempLabel}>
              {t("machine.health.chart.text")}
            </AppTypography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: "1rem",
          display: "flex",
          position: "relative",
          margin: "1rem 0 1rem 0",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {!isDemoMachine && <TopLedgendIcons daysPerType={daysPerType} />}
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "0px",
            pr: 2,
          }}
        >
          <DateTimeRangePicker
            timespan={healthGraphSelectedRange}
            selectedActionToDispatch={setHealthTimespan}
            origin="health"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default GraphBaseHeader
