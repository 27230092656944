import { Amplify, Auth as AWSAuth } from "aws-amplify"
import axios from "axios"
import { batch } from "react-redux"
import { deleteToken } from "libraries/firebaseInit"
import { logOut } from "store/rootSlice"
import { millisecondsToSeconds } from "date-fns"
import { setOrganizationId } from "store/Organisation/organizationSlice"
import { store } from "store/store"
import { TBackendEvironments } from "types/misc"

import { AWS_COGNITO_CONFIG, ENVS } from "../config"

const config = {
  ...AWS_COGNITO_CONFIG,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_WEB_CLIEND_ID,
}

Amplify.configure({
  Auth: config,
})

export const logout = async () => {
  axios.defaults.baseURL =
    ENVS[process.env.REACT_APP_BACKEND_ENV as TBackendEvironments]

  try {
    await deleteToken()
    const res = await AWSAuth.signOut()
    batch(() => {
      store.dispatch(setOrganizationId(null))
      store.dispatch(logOut())
    })
    return res
  } catch (error) {
    return error
  }
}

export const getNewToken = async () => {
  try {
    const session = await AWSAuth.currentSession()
    const idTokenExpire = session.getIdToken().getExpiration()
    const refreshToken = session.getRefreshToken()
    const currentTimeSeconds = millisecondsToSeconds(new Date().valueOf())
    if (idTokenExpire < currentTimeSeconds) {
      const cognitoUser = await AWSAuth.currentAuthenticatedUser()
      cognitoUser.refreshSession(refreshToken, (err: any, data: any) => {
        if (err) {
          AWSAuth.signOut()
        }

        return data.getIdToken().getJwtToken()
      })
    }

    return session.getIdToken().getJwtToken()
  } catch (e) {
    console.log("error", e)
    await AWSAuth.signOut()
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    return ""
  }
}
