import { el, enGB } from "date-fns/locale"
import { formatWithOptions as format } from "date-fns/fp"
import { getStatusColor } from "core/helpers"
import { store } from "store/store"
import { subDays, subMonths, subWeeks } from "date-fns"

import { Theme } from "@mui/material"

export const simplifyResponseData = (dataArray: any) => {
  const result: any = []
  dataArray.forEach((element: any = {}) => {
    const { data } = element.data ?? {}
    result.push(data)
  })

  return result
}

export const sumVariableArrays = (array: any[]): any[] => {
  if (!Array.isArray(array)) {
    return []
  }

  if (array.length === 0) {
    return []
  }

  const arr = simplifyResponseData(array)

  const result = []

  const { length } = arr[0] ?? []

  for (let i = 0; i < length; i++) {
    const newObj = {}

    for (let j = 0; j < arr.length; j++) {
      const obj = arr[j][i]

      for (const property in obj) {
        if (obj.hasOwnProperty(property) && typeof obj[property] === "number") {
          // @ts-ignore
          newObj[property] = (newObj[property] || 0) + obj[property]
        }
      }
    }

    result.push(newObj)
  }

  return result.reverse()
}

export const _getBarChartLabels = (elementIndex: any) => {
  const today = new Date()
  const selectedLanguage = store?.getState()?.global?.language || "en"

  const getLanguage: any = (language: string) => {
    switch (language) {
      case "el":
        return el
      case "en":
        return enGB

      default:
        return enGB
    }
  }

  const { machineStatusHistoryDuration } = store.getState().machines

  switch (machineStatusHistoryDuration) {
    case "12_month": {
      const month: any = subMonths(today, elementIndex)
      return format({ locale: getLanguage(selectedLanguage) }, "MMM yy", month)
    }

    case "1_month": {
      const week = subWeeks(today, elementIndex)
      const prevWeek = subWeeks(today, elementIndex + 1)
      return `${format(
        { locale: getLanguage(selectedLanguage) },
        "d MMM",
        prevWeek,
      )} - ${format({ locale: getLanguage(selectedLanguage) }, "d MMM", week)}`
    }

    case "1_week": {
      const day = subDays(today, elementIndex)
      return format({ locale: getLanguage(selectedLanguage) }, "EEEE", day)
    }

    default:
      return ""
  }
}

export const getBarGraphDurationLabel = (duration: string) => {
  switch (duration) {
    case "1_week":
      return "week"
    case "1_month":
      return "month"
    case "12_month":
      return "year"
  }
}

export const transformDataForGraph = (data: any[], theme: Theme) => {
  const normalColor = !!Object.keys(theme).length
    ? getStatusColor("normal", theme)?.main
    : ""

  const warningColor = !!Object.keys(theme).length
    ? getStatusColor("warning", theme)?.main
    : ""

  const criticalColor = !!Object.keys(theme).length
    ? getStatusColor("critical", theme)?.main
    : ""

  const datasets = [
    {
      data: data.map((element: any) => element.normal).reverse(),
      label: "normal",
      backgroundColor: normalColor,
    },
    {
      data: data.map((element: any) => element.warning).reverse(),
      label: "warning",
      backgroundColor: warningColor,
    },
    {
      data: data.map((element: any) => element.critical).reverse(),
      label: "critical",
      backgroundColor: criticalColor,
    },
  ]

  const labels = data.map((_, index: number) => _getBarChartLabels(index))

  return {
    labels: labels.reverse(),
    datasets: datasets,
  }
}
