import { Box, lighten } from "@mui/material"
import {
  capitalizeFistLetter,
  getDurationInState,
  getDurationInStateLabel,
} from "core/helpers"
import {
  getDepartmentsSelector,
  useGetDepartmentInfoQuery,
} from "store/Department/departmentSlice"
import { setIsDrawerDetailsOpen } from "store/rootSlice"
import {
  setSelectedMachine,
  useGetMachineCardQuery,
  useGetMachineCardStatusInfoSupaQuery,
} from "store/Machines/machinesSlice"
import { useAppDispatch, useIsDarkTheme } from "core/hooks"

import AppCardLoader from "core/components/AppCardLoader"
import AppTypography from "core/components/AppTypography"
import BaseCard from "../BaseCard"
import ConditionStatusInformation from "./subcomponents/ConditionStatusInformation"
import { IProductionLine } from "models/interfaces/productionLine"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import { MachineCollection } from "models/organization"
import MachineTitle from "./subcomponents/MachineTitle"
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined"
/* eslint-disable no-shadow */
import React, { useEffect } from "react"
import { RootState } from "store/store"
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined"

import { styles } from "./styles"
import { useGetProductionLineInfoQuery } from "store/Production_lines/productionLineSlice"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { DEMO_RIG_MACHINE_ID } from "core/constants/ui"

const MachineCard = ({ machine }: any) => {
  const { isDarkTheme, theme } = useIsDarkTheme()

  const { machine_id: machineId = "", asset_collection: assets = [] } = machine

  const selectStateSite = (state: RootState) => state.sites
  const { selectedSiteId: siteUUID } = useSelector(selectStateSite)

  const selectState = (state: RootState) => state.global
  const { isDrawerDetailsOpen } = useSelector(selectState) ?? false

  const selectedMachineId = useSelector(
    (state: RootState) => state.machines.machineSelected?.machine_id ?? "",
  )

  const navigate = useNavigate()

  const isCardHighlighted =
    selectedMachineId === machineId && isDrawerDetailsOpen

  const {
    machineName = "",
    productionLineId = "",
    refetch,
    isFetching,
    isError,
  } = useGetMachineCardQuery(
    { machineId },
    {
      selectFromResult: ({ data, isFetching, isError }) => ({
        machineName: data?.machine_name,
        machineId: data?.machine_id,
        productionLineId: data?.production_line_id,
        isFetching,
        isError,
      }),
    },
  )

  const departments = useSelector((state: RootState) =>
    getDepartmentsSelector(state, siteUUID ?? ""),
  ).find(({ production_line_collection }: any) =>
    production_line_collection.find(
      ({ production_line_id: id }: any) => id === productionLineId,
    ),
  )

  const prodId = departments?.production_line_collection.find(
    ({ machine_collection: machines }: IProductionLine) =>
      machines.find(
        ({ machine_id: id }: MachineCollection) => id === machineId,
      ),
  )?.production_line_id

  const { departmentName } = useGetDepartmentInfoQuery(
    {
      deptID: departments?.department_id,
    },
    {
      skip: !departments?.department_id,
      selectFromResult: ({ data }) => ({
        departmentName: data?.department_name,
      }),
    },
  )

  const { productionLineName } = useGetProductionLineInfoQuery(
    {
      productionLineId: prodId,
    },
    {
      skip: !prodId,
      selectFromResult: ({ data }) => ({
        productionLineName: data?.production_line_name,
      }),
    },
  )

  const {
    data,
    isFetching: isFetchingStatus,
    refetch: refetchData,
  } = useGetMachineCardStatusInfoSupaQuery(
    {
      machineId,
    },
    { skip: !machineId },
  )

  const {
    state: machineStatus = isFetchingStatus ? "" : "training",
    duration = 0,
    machineHealth: health = 0,
  } = data || {}

  const machineTimeInCurrentStatus = duration

  const durationInStateLabel = getDurationInStateLabel(
    machineTimeInCurrentStatus,
  )

  const durationInState = getDurationInState(machineTimeInCurrentStatus)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const handleOnClick = () => {
    dispatch(setSelectedMachine(machine))
    dispatch(setIsDrawerDetailsOpen(true))
  }

  const handleMachineTitleClick = () => {
    dispatch(setSelectedMachine(machine))
    navigate("/machineDetailScreen")
  }

  const assetsPerMachine = assets.length

  const handleOnRetry = () => refetch()

  const healthScoreLabel = health < 10 ? "<10%" : `${health}%`

  useEffect(() => {
    if (DEMO_RIG_MACHINE_ID !== machineId) return

    const intervalId = setInterval(() => {
      refetchData()
    }, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <BaseCard
      sx={
        isDarkTheme
          ? {
              ...styles.baseContainer(isCardHighlighted),
              // display: shouldDisplay,
              backgroundColor: lighten(theme.palette.background.paper, 0.08),
            }
          : {
              ...styles.baseContainer(isCardHighlighted),

              // display: shouldDisplay,
            }
      }
      customHeader={
        <MachineTitle
          onClick={handleMachineTitleClick}
          machineName={`${machineName}`}
          status={machineStatus}
        />
      }
    >
      <AppCardLoader
        isLoading={isFetching}
        hasError={isError}
        onRetry={handleOnRetry}
        style={{
          minHeight: 120,
        }}
      >
        <Box onClick={handleOnClick} sx={styles.container}>
          <ConditionStatusInformation
            sx={styles.containerLeft}
            icon={<MonitorHeartOutlinedIcon />}
            info={healthScoreLabel}
            status={machineStatus}
            tooltip={capitalizeFistLetter(t("health.score.text"))}
          />

          <ConditionStatusInformation
            sx={{ ...styles.containerRight, ml: "2px" }}
            icon={<Inventory2OutlinedIcon sx={{ fontSize: "large" }} />}
            info={assetsPerMachine}
            tooltip={capitalizeFistLetter(t("assets.text"))}
          />

          <ConditionStatusInformation
            sx={styles.containerLeft}
            icon={<TimelapseOutlinedIcon />}
            tooltip={capitalizeFistLetter(t("duration.in.current.state.text"))}
            info={
              <Box sx={styles.infoBoxContainer}>
                <AppTypography sx={{ fontSize: "0.938rem" }}>
                  {durationInState}
                </AppTypography>
                <AppTypography sx={{ fontSize: "0.625rem", ml: 1 / 2 }}>
                  {t(durationInStateLabel)}
                </AppTypography>
              </Box>
            }
          />

          <ConditionStatusInformation
            sx={styles.containerRight}
            icon={<LocationOnOutlinedIcon />}
            info={[productionLineName, departmentName]}
            tooltip={capitalizeFistLetter(t("location.text"))}
          />
        </Box>
      </AppCardLoader>
    </BaseCard>
  )
}

export default MachineCard
