export const styles = {
  root: {
    width: "calc(100vw - 100px)",
    height: "calc(100vh - 90px)",
  },
  title: {
    margin: "4px",
  },
  stateLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "4px",
    fontWeight: "bold",
    fontSize: "12px",
    borderRadius: 1,
    padding: "0 16px 0 16px",
    color: "white",
  },
  departmentLabel: {
    margin: "4px",
    color: "grey",
  },
}
