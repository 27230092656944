import { Box, Skeleton } from "@mui/material"
import AppTypography from "core/components/AppTypography"
import { t } from "i18next"
import React from "react"

import { styles } from "./styles"
import { useGetLastTempQuery } from "store/Assets/assetsSlice"

interface ICurrentTemperature {
  assetId?: string | null
}

const CurrentTemperature = ({ assetId = null }: ICurrentTemperature) => {
  const { data, isFetching } = useGetLastTempQuery({ assetId })

  const latestValue = Number(data?.temperature).toFixed(1) ?? ""

  return (
    <Box sx={styles.boxStyles}>
      {isFetching ? (
        <Skeleton variant="text" width={100} height={20} />
      ) : (
        <>
          <AppTypography sx={{ fontSize: "0.75rem" }} capitalizeFirstLetter>
            {`${t("current.temperature.text")}:`}
          </AppTypography>

          <AppTypography sx={styles.tempText}>{`${latestValue}`}</AppTypography>

          <AppTypography sx={{ fontSize: "0.938rem" }}>°C</AppTypography>
        </>
      )}
    </Box>
  )
}

export default CurrentTemperature
