import {
  IGenerateAssetOverallInformation, IGenerateConditionMonitoringCard,
  IGetBeatBoxRMSByBeatBoxIDFormedTimeFrame, IPostGetTimeseriesParams,
  RMS, temperature,
} from "types/assetsApi";

import BAPI from "../api";
import { supabase } from "../supabase";

const AssetsAPI = {
  generateAssetOveralInfrormation(params: IGenerateAssetOverallInformation) {
    const { machineId, assetId } = params
    return BAPI.get(
      `/view/asset/information/machine/${machineId}/asset/${assetId}/info/overal`,
    )
  },

  getRMS(params: RMS) {
    const { assetId, axis, fromEpoch, toEpoch } = params
    if (fromEpoch && toEpoch) {
      return BAPI.get(
        `/rms/${assetId}?axis=${axis}&start_time=${fromEpoch}&end_time=${toEpoch}`,
      )
    }

    return BAPI.get(`rms/${assetId}`)
  },

  getTemp(params: temperature) {
    const { assetId, start_time, end_time } = params
    if (start_time && end_time) {
      return BAPI.get(
        `/temp/${assetId}?start_time=${start_time}&end_time=${end_time}`,
      )
    }

    return BAPI.get(`temp/${assetId}`)
  },

  postGetTsData(body: IPostGetTimeseriesParams) {
    return BAPI.post("/get_ts_data", body)
  },

  getVelocity(params: RMS) {
    const { assetId, axis, fromEpoch, toEpoch } = params
    if (fromEpoch && toEpoch) {
      return BAPI.get(
        `/vel/${assetId}?select_axis=${axis}&from_epoch=${fromEpoch}&to_epoch=${toEpoch}`,
      )
    }

    return BAPI.get(`vel/${assetId}`)
  },

  getBeatBoxRMSByBeatBoxIDFormedTimeFrame(
    params: IGetBeatBoxRMSByBeatBoxIDFormedTimeFrame,
  ) {
    const {
      beatBoxId,
      fromEpoch,
      toEpoch,
      select,
      frequencyHour = 0,
      orderBy = "time",
      orderByType = "DESC",
    } = params

    return BAPI.get(
      `/view/historical/beatbox/rms/measurements/${beatBoxId}/time-frame?frequency_hour=${frequencyHour}&fromEpoch=${fromEpoch}&orderBy=${orderBy}&orderByType=${orderByType}&select=${select}&toEpoch=${toEpoch}`,
    )
  },

  generateConditionMonitoringCard(params: IGenerateConditionMonitoringCard) {
    const { machineId, assetId } = params

    return BAPI.get(
      `/view/asset/information/machine/${machineId}/asset/${assetId}/info/conditionMonitoring`,
    )
  },

  getAssetInfo(assetId: string) {
    return BAPI.get(`/asset/${assetId}`)
  },

  postGetCbDataSupa(payload: IPostGetCbDataSupaParams) {
    const fixTimeToIso = (input: number | string): string => {
      let date: Date

      if (typeof input === "number") {
        const threshold = Math.pow(10, 12)
        if (input < threshold) {
          input *= 1000
        }

        date = new Date(input)
      } else {
        date = new Date(input)
      }

      return date.toISOString()
    }

    let finalPayload: IPostGetCbDataSupaParams = {
      ...payload,
    }

    if (payload.start_time && payload.end_time) {
      const fixedTime = {
        start_time: fixTimeToIso(payload.start_time),
        end_time: fixTimeToIso(payload.end_time),
      }

      finalPayload = {
        asset: payload.asset,
        ...fixedTime,
      }
    }

    return supabase.rpc("get_metrics_time_range_full", finalPayload)
  },
}

export type measurementType =
  | "acc_rms_x"
  | "acc_rms_y"
  | "acc_rms_z"
  | "temperature"
  | "time"
  | "vel_rms_x"
  | "vel_rms_y"
  | "vel_rms_z"

export const transformSupaResponseData = (data: any, type: measurementType) =>
  !!data ? data?.data.map((item: any) => [item.time, item[type]]) : []

export default AssetsAPI

export interface IPostGetCbDataSupaParams {
  asset: string
  end_time: string
  start_time: string
}
