import * as React from "react"

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

import { useDispatch } from "react-redux"

import { menuOptions } from "./constants"
import { styles } from "./styles"
import { useTranslation } from "react-i18next"
import AppTypography from "../AppTypography"

import { ActionCreatorWithPayload } from "@reduxjs/toolkit"

interface IDateRangePicker {
  selectedActionToDispatch: ActionCreatorWithPayload<any>
  timespan: number
  selectOptions?: {
    minutes: number
    labelCode: string
  }[]
}

const DateRangePicker = ({
  timespan,
  selectedActionToDispatch,
  selectOptions = menuOptions,
}: IDateRangePicker) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(selectedActionToDispatch(event.target.value))
  }
  return (
    <Box sx={styles.formContainer}>
      <FormControl sx={styles.formControl} variant="standard" fullWidth>
        <Select
          sx={{
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
            },
          }}
          disableUnderline
          labelId="vibration-timespan-select-menu"
          id="vibration-timespan-select"
          value={`${timespan}`}
          label="timespan"
          onChange={handleChange}
        >
          {selectOptions.map((option: any) => (
            <MenuItem key={option.labelCode} value={option.minutes}>
              <AppTypography
                capitalizeFirstLetter
                sx={{
                  color: "text.secondary",

                  textAlign: "center",
                  m: 1,
                }}
              >
                {t(option.labelCode)}
              </AppTypography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
export default DateRangePicker
