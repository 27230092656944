export const WIDTH_ANOMALY_DETECTION_GRAPH = 1200

export const DEFAULT_MARGIN = 3

export const DEFAULT_SPACING_FACTOR = 8

export const DEFAULT_ANIMATION_DURATION = 200 //ms
export const GRID_SPACING = 2

export const DRAWER_WIDTH = 150
export const ROW_HEIGHT = 600

export const EUROPEAN_PROJECTS_URLS = [
  "level-up",
  "moses",
  "prelude",
  "localhost",
]

export const MOCKING_URL_PATH = "mocked-backend"
export const DYNAMIC_UI_URL_PATH = "dynamic-ui"

export const DEMO_RIG_MACHINE_ID = "01HSXNVZB00V4CCE3776ZR9XAT"
export const CORE_GROUP_ORGANIZATION_ID = "01GWVD0DP01MRFHEWBZT94G27W"

export const renderFlags = {
  isFirstRenderHealth: true,
  isFirstRenderTemp: true,
  isFirstRenderRMS: true,
  isFirstRenderMachineInfo: true,
  isFirstRenderDashboard: true,
}
