import { Box } from "@mui/system"
import AppTypography from "core/components/AppTypography"
import React from "react"
import AxisSelector from "screens/Facilities/subcomponents/MachineVibrationGraph/subcomponents/AxisSelector"

const SimpleHeader = ({ title, sx = {} }: any) => (
  <Box sx={sx}>
    <AppTypography
      typographyProps={{
        align: "right",
        fontSize: "0.75rem",
        color: "text.secondary",
      }}
    >
      {title}
    </AppTypography>
    <AxisSelector />
  </Box>
)

export default SimpleHeader
