import React from "react"

import AppTypography from "core/components/AppTypography"
import { format } from "date-fns"
import {
  getDepartmentsSelector,
  useGetDepartmentInfoQuery,
} from "store/Department/departmentSlice"
import { getStatusColor, replaceUnderscores } from "core/helpers"
import { IProductionLine } from "models/interfaces/productionLine"
import { MachineCollection } from "models/organization"
import { RootState } from "store/store"
import {
  useGetMachineCardQuery,
  useGetMachineCardStatusInfoSupaQuery,
} from "store/Machines/machinesSlice"
import { useGetProductionLineInfoQuery } from "store/Production_lines/productionLineSlice"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"

import BaseCard from "../BaseCard"
import ImgWithPlaceholder from "../../ImgWithPlaceholder"
import SpecItem from "./subcomponents/SpecItem"
import { styles } from "./styles"

const MachineSpecsCard = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const machinesState = (state: RootState) => state.machines
  const siteState = (state: RootState) => state.sites

  const { selectedSiteId: siteUUID } = useSelector(siteState)
  const { machineSelected } = useSelector(machinesState)

  const { machine_id: machineId = "" } = machineSelected || {}
  const {
    imageUrl = "",
    productionLineId = "",
    machineName = "",
  } = useGetMachineCardQuery(
    {
      machineId,
    },
    {
      selectFromResult: ({ data, isFetching, isError }) => ({
        productionLineId: data?.production_line_id,
        imageUrl: data?.machine_description,
        machineName: data?.machine_name,
        // TO BE CALLED FROM INFLUX ENDPOINT
        isFetching,
        isError,
      }),
    },
  )

  const departments = useSelector((state: RootState) =>
    getDepartmentsSelector(state, siteUUID ?? ""),
  ).find(({ production_line_collection }: any) =>
    production_line_collection.find(
      ({ production_line_id: id }: any) => id === productionLineId,
    ),
  )

  const prodId = departments?.production_line_collection.find(
    ({ machine_collection: machines }: IProductionLine) =>
      machines.find(
        ({ machine_id: id }: MachineCollection) => id === machineId,
      ),
  )?.production_line_id

  const { departmentName } = useGetDepartmentInfoQuery(
    {
      deptID: departments?.department_id,
    },
    {
      skip: !departments?.department_id,
      selectFromResult: ({ data }) => ({
        departmentName: data?.department_name,
      }),
    },
  )

  const { productionLineName } = useGetProductionLineInfoQuery(
    {
      productionLineId: prodId,
    },
    {
      skip: !prodId,
      selectFromResult: ({ data }) => ({
        productionLineName: data?.production_line_name,
      }),
    },
  )

  const res = useGetMachineCardQuery(
    {
      machineId,
    },
    { skip: !machineId },
  )

  const registrationDate = format(
    new Date(res.data?.machine_timestamp ?? 0),
    "dd MMM yyyy",
  )

  const { data } = useGetMachineCardStatusInfoSupaQuery(
    {
      machineId,
    },
    {
      skip: !machineId,
    },
  )

  const machineState = data?.state ?? ""

  const specList = [
    { label: "machine.id.text", text: `${machineId}` },
    { label: "registrationDate", text: registrationDate },
    { label: "productionLineName.text", text: `${productionLineName}` },
    { label: "department", text: `${departmentName}` },
  ]
  return (
    <BaseCard
      sx={{
        ...styles.container,
        borderColor: getStatusColor(machineState, theme).main,
      }}
    >
      <Box sx={styles.machinePhotoContainer}>
        <ImgWithPlaceholder
          imgSrc={imageUrl}
          placeholderText={t("add.machine.photo")}
        />
      </Box>

      <Box sx={styles.specsContainer}>
        <Box sx={styles.headerContainer}>
          <AppTypography
            variant="h2"
            sx={styles.headerLabel}
            capitalizeFirstLetter
          >
            {replaceUnderscores(machineName)}
          </AppTypography>
          {/* <Tooltip
            placement="left"
            arrow
            title={<AppTypography>{t("documentation.text")}</AppTypography>}
          >
            <DescriptionOutlined
              sx={{ height: "16px", width: "16px" }}
              color="primary"
            />
          </Tooltip> */}
        </Box>
        {specList.map((item: any) => (
          <SpecItem key={item.label} label={item.label} text={item.text} />
        ))}
      </Box>
    </BaseCard>
  )
}

export default MachineSpecsCard
