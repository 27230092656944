import React from "react"

import { getIsTheUrlEUProject } from "core/helpers"

import { Box } from "@mui/material"

import logoCoreBeat from "../../../../assets/logos/cb_logo_orange.png"
import logoCoreIC from "../../../../assets/logos/coreic.png"
import { styles } from "./styles"

const Logo = () => {
  const logo = getIsTheUrlEUProject() ? logoCoreIC : logoCoreBeat

  return (
    <Box data-testid="logo" sx={styles.logoContainer}>
      <img src={logo} alt="logo" style={styles.logo} />
    </Box>
  )
}

export default Logo
