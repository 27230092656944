import { apiSlice } from "store/queryApi"
import DashboardAPI from "core/api/DashboardApi"
import { IFindOrganisationById } from "types/dashboardApi"
import { RootState } from "store/rootReducer"
import { supabase } from "core/api/supabase"
import { transformOrganizationData } from "core/api/DashboardApi/utils"

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

const organizationAdapter = createEntityAdapter({
  selectId: (organization: any) => organization.organizationId,
})

export const initialState = organizationAdapter.getInitialState({
  organizationId: null,
  organizationsCollection: [],
  organizationSelected: null,
}) as any

export const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizationId: (state, action) => {
      state.organizationId = action.payload
    },
    setOrganizationSelected: (state, action) => {
      state.organizationSelected = action.payload
    },
    addOrganization: organizationAdapter.addOne,
  },
})

export const organizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<any, IFindOrganisationById>({
      transformResponse: (response: any) => {
        const transformedResponse = transformOrganizationData(response)
        return transformedResponse
      },
      query: ({ uuid }: IFindOrganisationById) => ({
        api: DashboardAPI.findOrganisationById({
          uuid,
        }),
      }),
      // one year
      keepUnusedDataFor: 31557600,
    }),
    getOrganizationBarStateDuration: builder.query<any, any>({
      queryFn: async (arg: any, _api, _extraOptions, _baseQuery) => {
        const {
          orgID,
          duration,
        }: {
          orgID: string
          duration: "month" | "week" | "year"
        } = arg

        const data = await DashboardAPI.getBarDurationRS({
          orgID,
          duration,
        })

        return { data }
      },
    }),
    getOrganizationBarStateDurationTest: builder.query<any, any>({
      queryFn: async (arg: any, _api, _extraOptions, _baseQuery) => {
        const {
          organizationId,
          duration,
        }: {
          organizationId: string
          duration: "month" | "week" | "year"
        } = arg

        const rpcName = `get_machine_condition_hourly_summary_${duration}`

        const data = await supabase.rpc(rpcName, {
          organisation_id_in: organizationId,
        })

        return { data }
      },
    }),
  }),
})

export const {
  useGetOrganizationQuery,
  useGetOrganizationBarStateDurationQuery,
  useGetOrganizationBarStateDurationTestQuery,
} = organizationApiSlice

export const { selectById: selectOrgById } = organizationAdapter.getSelectors(
  (state: RootState) => state.organizations,
)

export const { setOrganizationId, addOrganization, setOrganizationSelected } =
  organizationSlice.actions
// export default organizationReducer
export default organizationSlice.reducer
