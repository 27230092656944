import { subDays, subHours, subMonths } from "date-fns"

export const predefinedBottomRanges = [
  {
    label: "Last 1 hour",
    value: [subHours(new Date(), 1), new Date()],
  },
  {
    label: "Last 3 hours",
    value: [subHours(new Date(), 3), new Date()],
  },
  {
    label: "Last 6 hours",
    value: [subHours(new Date(), 6), new Date()],
  },
  {
    label: "Last 12 hours",
    value: [subHours(new Date(), 12), new Date()],
  },
  {
    label: "Last 3 days",
    value: [subDays(new Date(), 3), new Date()],
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: "Last 15 days",
    value: [subDays(new Date(), 14), new Date()],
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: "Last 3 months",
    value: [subMonths(new Date(), 2), new Date()],
  },
  {
    label: "Last 6 months",
    value: [subMonths(new Date(), 5), new Date()],
  },
  {
    label: "Last 12 months",
    value: [subMonths(new Date(), 11), new Date()],
  },
]

export const getRanges = (label: any) => {
  const values = predefinedBottomRanges.find(
    (range) => range.label === label,
  )?.value

  const localOffset = new Date().getTime() - new Date(values![1]).getTime()

  const fromEpoch = new Date(
    new Date(values![0]).getTime() + localOffset,
  ).toISOString()
  const toEpoch = new Date(
    new Date(values![1]).getTime() + localOffset,
  ).toISOString()

  return {
    fromEpoch,
    toEpoch,
  }
}
