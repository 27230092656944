export const BORDER_RADIUS = "8px"

export function getRandomColor() {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
export const styles = {
  root: {
    backgroundColor: "transparent",
    borderRadius: BORDER_RADIUS,
    height: "80px",
    textAlign: "left",
  },
  tableCell: {
    textAlign: "left",
    backgroundColor: "background.paper",
  },
}
