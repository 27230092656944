export const styles = {
  root: {
    width: "calc(100vw - 100px)",
    height: "calc(100vh - 90px)",
  },
  title: {
    fontSize: 60,
  },
}

export const ROW_HEIGHT = 80
