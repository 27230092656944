import * as React from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"

import { visuallyHidden } from "@mui/utils"
import AlertRow from "../AlertRow"
import { ROW_HEIGHT } from "./styles"
import { AlertsResponse, useGetAlertsQuery } from "store/Alerts/alertsSlice"
import { RootState } from "store/rootReducer"
import { useSelector } from "react-redux"

interface HeadCell {
  disablePadding: boolean
  id: keyof AlertDataType
  label: string
  numeric: boolean
}

export interface AlertDataType {
  alerts_id: string | number
  alerts_organisation_id: string | number
  alerts_machine_id: string | number
  alerts_wc_asset_id: string | number
  created_at: string | number
  alerts_resolved: boolean | number
  alerts_resolved_state?: boolean | number
  alerts_resolved_by: string | number
  alerts_resolved_at: string | number
  alerts_health: number | number
  alerts_state: string | number
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AlertDataType,
  ) => void

  order: Order
  orderBy: string
}

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

type Order = "asc" | "desc"

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells: readonly HeadCell[] = [
  {
    id: "alerts_machine_id",
    numeric: false,
    disablePadding: true,
    label: "Machine name",
  },
  {
    id: "alerts_state",
    numeric: false,
    disablePadding: false,
    label: "Issue",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "Time",
  },
  {
    id: "alerts_resolved",
    numeric: false,
    disablePadding: false,
    label: "Alert Status",
  },
  {
    id: "alerts_resolved_at",
    numeric: true,
    disablePadding: false,
    label: "Resolved Time",
  },

  {
    id: "alerts_resolved_state",
    numeric: false,
    disablePadding: false,
    label: "",
  },
]

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
}: EnhancedTableProps) => {
  const createSortHandler =
    (property: keyof AlertDataType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type AlertsTabType = {
  selection: "all" | "resolved" | "unresolved"
}

const AlertsTab = ({ selection }: AlertsTabType) => {
  const [order, setOrder] = React.useState<Order>("desc")
  const [orderBy, setOrderBy] =
    React.useState<keyof AlertDataType>("created_at")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const selectState = (state: RootState) => state.organizations
  const { organizationId } = useSelector(selectState)

  const { data } = useGetAlertsQuery({
    organizationId: organizationId,
  })

  let filteredData: AlertsResponse | undefined

  switch (selection) {
    case "all":
      filteredData = data
      break
    case "resolved":
      filteredData = data?.filter((item: AlertDataType) => item.alerts_resolved)

      break
    case "unresolved":
      filteredData = data?.filter(
        (item: AlertDataType) => !item.alerts_resolved,
      )
      break
    default:
      filteredData = data
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AlertDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData!.length) : 0

  const visibleRows = React.useMemo(() => {
    if (!filteredData) return []

    return stableSort(filteredData as any, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    )
  }, [filteredData, order, orderBy, page, rowsPerPage])

  return (
    <Box>
      {!!filteredData && (
        <Box>
          <TableContainer
            sx={{
              width: "100%",
              maxHeight: "calc(100vh - 330px)",
            }}
          >
            <Table
              padding="normal"
              sx={{
                ["&.MuiTable-root"]: {
                  borderSpacing: "0 16px",
                  padding: "0 16px 0 16px",
                  borderCollapse: "separate",
                },
              }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <AlertRow
                      key={row.alerts_id}
                      row={row as any}
                      labelId={labelId}
                    />
                  )
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: ROW_HEIGHT * emptyRows,
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, filteredData?.length]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  )
}

export default AlertsTab
