import { differenceInCalendarDays, format } from "date-fns"
import { getHealthBarGraphResolution, getTimeFormat, isOdd } from "core/helpers"

type TimeObject = {
  date: string
  value: number
}

export const interpolateGraphData = ({
  startDate,
  endDate,
  dataObject,
}: any) => {
  const newData: TimeObject[] = []
  const currentDate = new Date(startDate)

  currentDate.setMinutes(0, 0, 0)

  const _endDate = new Date(endDate).getTime()

  while (currentDate.getTime() <= _endDate) {
    const existingObject = dataObject.find(
      (obj: any) =>
        new Date(obj.date).getTime() * 1000 === currentDate.getTime(),
    )

    existingObject
      ? newData.push(existingObject)
      : newData.push({ date: currentDate.toISOString(), value: 3 })

    currentDate.setHours(currentDate.getHours() + 1)
  }

  return { data: newData }
}

export const getHealthBarGraphDecimationForDemoRig = ({ start, end }: any) => {
  let multiplier

  const dateRange = differenceInCalendarDays(new Date(end), new Date(start))

  if (dateRange < 1) {
    multiplier = 1
  } else if (dateRange < 3) {
    multiplier = 30
  } else if (dateRange < 7) {
    multiplier = 60
  } else if (dateRange < 30) {
    multiplier = 120
  } else if (dateRange < 90) {
    multiplier = 240
  } else if (dateRange < 180) {
    multiplier = 480
  } else if (dateRange < 365) {
    multiplier = 720
  } else {
    multiplier = 1440
  }

  return multiplier
}

export const interpolateGraphDataForDemoMachine = ({
  startDate,
  endDate,
  dataObject,
}: any) => {
  const newData: TimeObject[] = []
  const currentDate = new Date(startDate)

  const SECONDS_OF_DEMORIG = 60

  const multiplier = getHealthBarGraphDecimationForDemoRig({
    start: startDate,
    end: endDate,
  })

  const addedStep = Math.floor(SECONDS_OF_DEMORIG * multiplier)

  currentDate.setMinutes(Math.floor(currentDate.getMinutes() / 2) * 2)
  currentDate.setSeconds(0, 0) // Set seconds, milliseconds to 0

  const _endDate = new Date(endDate).getTime()

  while (currentDate.getTime() <= _endDate) {
    const existingObject = dataObject.find(
      (obj: any) =>
        new Date(obj.date).getTime() * 1000 === currentDate.getTime(),
    )

    existingObject
      ? newData.push(existingObject)
      : newData.push({ date: currentDate.toISOString(), value: 3 })

    currentDate.setSeconds(currentDate.getSeconds() + addedStep) // Add a minute
  }

  return { data: newData }
}

export const GRAPH_RESOLUTION = 1
export const calculateDaysFromMinutes = (
  hours: number,
  daysDifference?: number,
) => Math.floor(hours / getHealthBarGraphResolution(daysDifference) / 24)

export const transformHealthHistoryDataForDemoMachine = ({
  rawData,

  timeSelector,
}: any) => {
  const labels: any[] = []
  const critical: any[] = []
  const warning: any[] = []
  const normal: any[] = []
  const idle: any[] = []
  const epoch: any[] = []

  const THIRTY_SECONDS = 30 * 1000

  const fillArray = ({ type, time, _epoch }: any) => {
    const resolution = 1

    for (let i = 0; i < resolution; i++) {
      switch (type) {
        case null:
          critical.push(null)
          warning.push(null)
          normal.push(null)
          idle.push(0)
          break
        case 3:
          critical.push(null)
          warning.push(null)
          normal.push(null)
          idle.push(0)
          break
        case 2:
          critical.push(3)
          warning.push(null)
          normal.push(null)
          idle.push(null)
          break
        case 1:
          warning.push(2)
          critical.push(null)
          normal.push(null)
          idle.push(null)

          break
        case 0:
          normal.push(1)
          critical.push(null)
          warning.push(null)
          idle.push(null)
          break
      }

      labels.push(time)
      epoch.push(_epoch)
    }
  }

  const _timeSelector = {
    fromEpoch: new Date(timeSelector.fromEpoch).getTime(),
    toEpoch: new Date(timeSelector.toEpoch).getTime(),
  } as any

  for (const el of rawData) {
    if (!el?.date) continue

    const _epoch = el.date * 1000

    const time = format(
      _epoch - THIRTY_SECONDS,
      getTimeFormat(undefined, _timeSelector),
      _timeSelector,
    )

    fillArray({ type: el.value, time, _epoch })
  }

  return {
    labels,
    critical,
    warning,
    normal,
    idle,
    epoch,
  }
}

export const transformHealthHistoryData = ({
  rawData,
  daysDifference,
  timeSelector,
}: any) => {
  const labels: any[] = []
  const critical: any[] = []
  const warning: any[] = []
  const normal: any[] = []
  const idle: any[] = []
  const epoch: any[] = []

  const ONE_HOUR = 60 * 60 * 1000

  const fillArray = ({ type, time, _epoch }: any) => {
    const resolution = getHealthBarGraphResolution(daysDifference)

    for (let i = 0; i < resolution; i++) {
      switch (type) {
        case null:
          critical.push(null)
          warning.push(null)
          normal.push(null)
          idle.push(0)
          break
        case 3:
          critical.push(null)
          warning.push(null)
          normal.push(null)
          idle.push(0)
          break
        case 2:
          critical.push(3)
          warning.push(null)
          normal.push(null)
          idle.push(null)
          break
        case 1:
          warning.push(2)
          critical.push(null)
          normal.push(null)
          idle.push(null)

          break
        case 0:
          normal.push(1)
          critical.push(null)
          warning.push(null)
          idle.push(null)
          break
      }

      labels.push(time)
      epoch.push(_epoch)
    }
  }

  const _timeSelector = {
    fromEpoch: new Date(timeSelector.fromEpoch).getTime(),
    toEpoch: new Date(timeSelector.toEpoch).getTime(),
  } as any

  for (const el of rawData) {
    if (!el?.date) continue

    const _epoch = el.date * 1000

    const time = format(
      _epoch - ONE_HOUR,
      getTimeFormat(undefined, _timeSelector),
      _timeSelector,
    )

    fillArray({ type: el.value, time, _epoch })
  }

  return {
    labels,
    critical,
    warning,
    normal,
    idle,
    epoch,
  }
}

export const transformData = (data: any[]) => {
  const temp: any[] = []

  const dummyData = transformHealthHistoryData(data)

  let i = 0
  dummyData.labels.forEach((el: any, index: any) => {
    if (isOdd(index)) {
      if (!temp[index - 1]) {
        temp[index - 1] = data[i]
      }

      temp[index] = data[i]
      temp[index + 1] = data[i]
      i++
    }
  })

  return temp
}
