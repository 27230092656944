export const getInitials = (username: string | null): string => {
  // Handle null case
  if (!username) {
    return "👻"
  }

  // Split the username on special characters (customize the regex if needed)
  const parts = username.split(/[_.-@]/)

  if (parts.length === 3) {
    return parts[0].slice(0, 2).toUpperCase()
  }

  // Build the initials string, limiting to two letters
  let initials = ""
  for (const part of parts) {
    if (part && part.length > 0) {
      initials += part[0].toUpperCase()
    }
    if (initials.length === 2) {
      break // Stop if we have two initials
    }
  }

  return initials
}
export const stringToColor = (string: string) => {
  if (string === null) {
    return "#000000"
  }

  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = "#"

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
export const stringAvatar = function (name: string) {
  return {
    sx: {
      fontSize: "100%",
      margin: 1,
      width: 32,
      height: 32,
      backgroundColor: stringToColor(name),
    },
    children: getInitials(name),
  }
}
