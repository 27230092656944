import React from "react";

import { Box } from "@mui/material";
import Icon from "@mui/material/Icon";

import { AppIconProps } from "../..";
import { styles } from "./styles";

export const AppIcon = ({ iconCode }: AppIconProps) => (
  <Box sx={styles.appIcon}>
    {iconCode ? (
      <Icon sx={styles.thermostatIcon}>{iconCode}</Icon>
    ) : (
      <Icon>question_mark</Icon>
    )}
  </Box>
);
