import React from "react"

import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Typography } from "@mui/material"

import { styles } from "./styles"
import AlertsTab, { AlertDataType } from "./subcomponents/AlertsTab"
import { RootState } from "store/rootReducer"
import { useSelector } from "react-redux"
import { useGetAlertsQuery } from "store/Alerts/alertsSlice"

const AlertsScreen = () => {
  const [value, setValue] = React.useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const selectState = (state: RootState) => state.organizations
  const { organizationId } = useSelector(selectState)

  const { data } = useGetAlertsQuery({
    organizationId: organizationId,
  })

  const noOfResolvedAlerts = data?.filter(
    (alert: AlertDataType) => alert.alerts_resolved === true,
  ).length
  const noOfUnresolvedAlerts = data?.filter(
    (alert: AlertDataType) => alert.alerts_resolved === false,
  ).length
  const noOfAllAlerts = data?.length

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title} variant="h4" gutterBottom>
        Alerts
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={`Unresolved Alerts (${noOfUnresolvedAlerts})`}
              value="1"
            />
            <Tab label={`Resolved Alerts (${noOfResolvedAlerts})`} value="2" />
            <Tab label={`All (${noOfAllAlerts})`} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <AlertsTab selection="unresolved" />
        </TabPanel>

        <TabPanel value="2">
          <AlertsTab selection="resolved" />
        </TabPanel>
        <TabPanel value="3">
          <AlertsTab selection="all" />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default AlertsScreen
