import "firebase/compat/messaging"

import React from "react"

import firebase from "firebase/compat/app"
import { store } from "store/store"

import { FIREBASE } from "../core/config"
import ReactNotificationComponent from "../components/ReactNotificationComponent"
import { saveNotificationToLocalstorage } from "../core/helpers"
import { showNotification } from "../store/rootSlice"

const firebaseConfig = {
  apiKey: FIREBASE.API_KEY,
  authDomain: FIREBASE.AUTH_DOMAIN,
  projectId: FIREBASE.PROJECT_ID,
  storageBucket: FIREBASE.STORAGE_BUCKET,
  messagingSenderId: FIREBASE.MESSAGING_SENDER_ID,
  appId: FIREBASE.APP_ID,
  measurementId: FIREBASE.MEASUREMENT_ID,
}

const app = firebase.initializeApp(firebaseConfig)

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null
const publicKey = process.env.REACT_APP_VAPID_KEY
// const analytics = firebase.messaging.isSupported() ? getAnalytics(app) : null

// export const logCustomEvent = (event) => {
//   logEvent(analytics, event)
// }

export const getToken = async () => {
  if (!messaging) {
    return
  }

  let currentToken = ""

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey })
  } catch (error) {
    console.debug("An error occurred while retrieving token.", error)
  }
  return currentToken
}

export const deleteToken = async () => {
  if (!messaging) return

  try {
    await messaging.deleteToken()
    console.debug("Token deleted.")
  } catch (error) {
    console.debug("An error occurred while deleting token.", error)
  }
}

export const refreshToken = async (actionLabel) => {
  if (!messaging) return

  await messaging.deleteToken()
  const token = await messaging.getToken()
  console.debug(`${actionLabel.toUpperCase()} -> New token: `, token)

  return token
}

export const subscribeTokenToTopic = async (topic) => {
  const token = await getToken()

  const FCM_SERVER_KEY = process.env.REACT_APP_FCM_SERVER_KEY

  const googleApiUrl = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`

  fetch(googleApiUrl, {
    method: "POST",
    headers: new Headers({
      Authorization: `key=${FCM_SERVER_KEY}`,
    }),
  })
    .then((response) => {
      if (response.status < 200 || response.status >= 400) {
        console.debug(response.status, response)
      }
      console.debug(`Token : ${token} is subscribed to topic : ${topic}`)
    })
    .catch((error) => {
      console.error(error.result)
    })
  return true
}

export const onMessageListener = () => {
  if (!messaging) {
    return
  }

  try {
    messaging.onMessage((payload) => {
      const { notification } = payload

      const severity =
        payload?.data?.severity || "info" || "warning" || "critical"
      const image = payload?.data?.image

      saveNotificationToLocalstorage(notification)

      store.dispatch(
        showNotification({
          type: severity,
          content: (
            <ReactNotificationComponent
              title={notification.title}
              body={notification.body}
              image={image}
            />
          ),
        }),
      )
    })
  } catch (error) {
    console.log(error)
  }
}
