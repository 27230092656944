import React from "react"

import { TableCell, Avatar, Tooltip, Typography, Box } from "@mui/material"

import { AlertDataType } from "../AlertsTab"
import { stringAvatar } from "../../utils"

type ResolveLabelCellType = {
  rowStyle: {
    fontWeight: string
    backgroundColor: string
  }
  row: AlertDataType
}

const ResolveLabelCell = ({ rowStyle, row }: ResolveLabelCellType) => {
  const tooltipLabel = !row.alerts_resolved_by
    ? "unknown"
    : row.alerts_resolved_by

  return (
    <TableCell sx={rowStyle} align="right">
      {!!row.alerts_resolved ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            flexDirection: "row",
          }}
        >
          <Typography variant="body2">Resolved by</Typography>
          <Tooltip title={tooltipLabel} placement="top-start">
            <Avatar {...stringAvatar(row.alerts_resolved_by as string)} />
          </Tooltip>
        </Box>
      ) : (
        <Typography
          sx={{
            fontWeight: "bold",
          }}
          variant="body2"
        >
          Unresolved
        </Typography>
      )}
    </TableCell>
  )
}

export default ResolveLabelCell
