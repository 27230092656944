import {
  IBarStatusDuration, IFindOrganisationById,
  IGenerateHistoryMachineStatus, IGenerateInformatonCard,
  IGenerateMachineCard,
} from "types/dashboardApi";

import BAPI from "../";
import { supabase } from "../supabase";

const DashboardAPI = {
  async findOrganisationById(props: IFindOrganisationById) {
    const { uuid } = props

    return supabase
      .from("org_structure")
      .select("*")
      .eq("organisation_id", uuid)

    return BAPI.get(`organisation/${uuid}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
  generateInformatonCard(props: IGenerateInformatonCard) {
    const { siteUUID } = props
    return BAPI.get(`site/${siteUUID}/cards/overview`)
  },
  async generateMachineCard(props: IGenerateMachineCard) {
    const { machineId } = props

    return supabase
      .from("machine")
      .select("*")
      .eq("machine_id", machineId)
      .single()
  },

  getBarDurationRS(props: IBarStatusDuration) {
    const { orgID, duration } = props
    return BAPI.get(`bar_duration_rs/${orgID}?duration=${duration}`)
  },
  generateHistoryMachineStatus(props: IGenerateHistoryMachineStatus) {
    const { machineId, siteUUID, duration_number, duration_type } = props
    return BAPI.get(
      `site/${siteUUID}/machine/${machineId}/machineStatusHistory?find_by_${duration_type}s=${duration_number}`,
    )
  },
  getMachineBarStateDuration(props: any) {
    const { machine_id, start_time, end_time, duration } = props
    let url = `bar_duration/${machine_id}`
    if (duration) {
      url += `?duration=${duration}`
    }

    if (start_time) {
      url += `&start_time=${start_time}`
    }

    if (end_time) {
      url += `&end_time=${end_time}`
    }

    return BAPI.get(url)
  },
  generateMachinesOverview(props: IGenerateInformatonCard) {
    const { siteUUID } = props
    return BAPI.get(`site/${siteUUID}/machines/overview`)
  },
}

export default DashboardAPI
