import React from "react"

import { ROW_HEIGHT } from "core/constants/ui"
import { useInView } from "react-intersection-observer"
import { useTranslation } from "react-i18next"

import { CircularProgress, Grid, Paper } from "@mui/material"

import DynamicTimeseriesGraph from "../../../DynamicTimeseriesGraph"
import DynamicVibrationGraph from "../../../DynamicVibrationGraph"
import SimpleTimeseriesGraph from "../../../SimpleTimeseriesGraph"
import { styles } from "./styles"

const ComponentSelector = (props: any) => {
  const { dimension, row, column } = props

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const { t } = useTranslation()

  const selectGraph = () => {
    switch (column.graph.type) {
      case "timeseries":
        return (
          <DynamicTimeseriesGraph
            {...column.graph}
            heightFactor={row.heightFactor}
          />
        )
      case "vibration":
        return (
          <DynamicVibrationGraph
            {...column.graph}
            heightFactor={row.heightFactor}
          />
        )

      case "simpleTimeseries":
        return (
          <SimpleTimeseriesGraph
            {...column.graph}
            heightFactor={row.heightFactor}
          />
        )
    }
  }

  const height = row.heightFactor * ROW_HEIGHT

  return (
    <Grid
      ref={ref}
      sx={{
        m: 1,
      }}
      item
      md={dimension}
      sm={dimension}
      lg={dimension}
    >
      {inView ? (
        selectGraph()
      ) : (
        <Paper elevation={0} sx={{ ...styles.baseCard, height }}>
          <CircularProgress />
        </Paper>
      )}
    </Grid>
  )
}

export default ComponentSelector
