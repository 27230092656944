import React from "react"
import Tab from "@mui/material/Tab"
import { useAssetInfoQuery } from "store/Assets/assetsSlice"
import AppTypography from "core/components/AppTypography"

const AssetTab = ({ value: assetId, onChange, ...rest }: any) => {
  const { data } = useAssetInfoQuery(assetId, {
    skip: !assetId,
  })

  const assetName = data?.asset_name ?? ""

  return (
    <Tab
      value={assetId}
      label={
        <AppTypography capitalizeFirstLetter variant="button">
          {assetName}
        </AppTypography>
      }
      onChange={onChange}
      {...rest}
    />
  )
}

export default AssetTab
