const selectorContainer = {
  mx: 2,
  ":hover": {
    cursor: "pointer",
  },
}
export const styles = {
  axisContainer: {
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  axisLabel: {
    mx: 2,
  },
  label: {
    color: "text.secondary",
  },
  menuItem: {
    "& .MuiSvgIcon-root": {
      color: "text.secondary",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 1,
  },
  currentContainer: {
    display: "flex",
  },
  currentLabel: {
    color: "text.secondary",
    fontSize: "14px",
    mr: "5px",
  },
  currentValue: {
    color: "text.primary",
    fontSize: "14px",
    fontWeight: 700,
  },
  divider: {
    alignSelf: "center",
    height: "18px",
    mx: 2,
  },
  formContainer: {
    display: "flex",
    pr: 2,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeaderContainer: {
    // backgroundColor: "red",
    display: "flex",
    // position: "absolute",
    pr: 2,
    width: "100%",
    justifyContent: "space-between",
    // alignItems: "center",
    flexDirection: "row",
    minWidth: 120,
    mt: 2,
  },
  formControl: {
    minWidth: 120,
    width: "fit-content",
  },
  graphicEq: {
    color: "#7E7E7E",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  headerLabel: {
    alignItems: "center",
    display: "flex",
  },
  headerTempLabel: {
    color: "text.primary",
    textTransform: "capitalize",
  },
  infoIcon: {
    color: "text.secondary",
    height: "15px",
  },
  lastUpdate: {
    mr: 3,
    color: "text.secondary",
    fontSize: "0.75rem",
  },
  typeSelector: {
    display: "flex",
    flexDirection: "row",
    my: 2,
  },
  selectorContainer,
  selectorContainerSelected: { ...selectorContainer, fontWeight: "bold" },
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
}
