export const styles = {
  boxChartContainer: {
    height: "100%",
    m: 0,
    p: 0,
  },
  card: {
    m: 0,
    boxShadow: 1,
    display: "flex",
    flexDirection: "column",
    height: "180px",
  },
}
