import { isDynamicUIEnabled } from "core/helpers/dynamic_ui";

import {
  DashboardOutlined, NotificationsNone,
  ShowChart,
} from "@mui/icons-material";

export const exportCategoryTitles = () => {
  const defaultCategoryTitles = [
    {
      title: "category.dashboard",
      icon: DashboardOutlined,
      idsAvailable: ["dashboard"],
    },
    // {
    //   title: "category.facilities",
    //   icon: FactoryOutlined,
    //   idsAvailable: ["facilities", "machineDetailScreen"],
    // },

    {
      title: "category.alerts",
      icon: NotificationsNone,
      idsAvailable: ["alerts"],
    },
  ]

  if (isDynamicUIEnabled()) {
    defaultCategoryTitles.push({
      title: "category.dynamicMachineDetailScreen",
      icon: ShowChart,
      idsAvailable: [
        "dynamicMachineDetailScreen",
        "dynamicMachineDetailScreen",
      ],
    })
  }

  return defaultCategoryTitles
}
