import { Box, Button, TableCell } from "@mui/material"
import React from "react"
import AlertDialogResolve from "../AlertDialogResolve"
import { AlertDataType } from "../AlertsTab"
import { styles as stylesParent } from "../AlertRow/styles"
import { styles } from "./styles"

import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { useResolveAlertMutation } from "store/Alerts/alertsSlice"

type ResolveButtonType = {
  row: AlertDataType
}

const ResolveButton = ({ row }: ResolveButtonType) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <Box>
      <AlertDialogResolve setOpen={setOpen} open={open} row={row} />
      <TableCell
        sx={{
          ...stylesParent.tableCell,
          ...styles.root,
        }}
        align="right"
      >
        {!row.alerts_resolved && (
          <Button
            sx={styles.button}
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            Resolve Issue
          </Button>
        )}
      </TableCell>
    </Box>
  )
}

export default ResolveButton
