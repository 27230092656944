import React from "react"

import AppTypography from "core/components/AppTypography"
import { capitalizeFistLetter, tooltipTextHelper } from "core/helpers"
import { useTranslation } from "react-i18next"

import { Box, Tooltip } from "@mui/material"
import { GraphicEq } from "@mui/icons-material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import AxisSelector from "../AxisSelector"
import { styles } from "./styles"
import VelocityType from "./VelocityType"
import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
interface IGraphBaseHeaderProps {
  title?: string
  tooltipText?: any
}

const GraphBaseHeader = ({ title, tooltipText }: IGraphBaseHeaderProps) => {
  const { t } = useTranslation()
  const { language } = useSelector((state: RootState) => state.global)

  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Box sx={styles.headerContainer}>
          <Box sx={styles.headerLabel}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <GraphicEq sx={styles.graphicEq} />
              <AppTypography
                capitalizeFirstLetter
                variant="h6"
                sx={styles.headerTempLabel}
              >
                {t("vibration.text")}
              </AppTypography>
              {/* <Tooltip title={tooltipTextHelper(language, tooltipText)}>
                <InfoOutlinedIcon sx={styles.infoIcon} />
              </Tooltip> */}
            </Box>
            <AppTypography
              capitalizeFirstLetter
              variant="h6"
              sx={styles.headerTempLabel}
            >
              {title}
            </AppTypography>
          </Box>
        </Box>
        <VelocityType capitalizeFistLetter={capitalizeFistLetter} t={t} />
      </Box>

      <Box sx={styles.subHeaderContainer}>
        <AxisSelector />
      </Box>
    </Box>
  )
}

export default GraphBaseHeader
