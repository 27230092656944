import { apiSlice } from "store/queryApi"
import DashboardAPI from "core/api/DashboardApi"
import { DepartmentCollection } from "models/interfaces/department"
import { getBarChartLabels, selectDurationType } from "core/helpers"
import { getBarGraphDurationLabel } from "core/components/Plots/BarPlot/helpers"
import {
  IGenerateHistoryMachineStatus,
  IGenerateMachineCard,
} from "types/dashboardApi"
import { IInitialStateMachines } from "types/store"
import { ISite } from "models/interfaces/site"
import { Machine } from "models/interfaces/machine"
import { MachineCollection } from "models/organization"
import MachinesAPI from "core/api/MachinesApi"
import { MachinesOverview } from "models/interfaces/machinesOverview"
import { organizationApiSlice } from "store/Organisation/organizationSlice"
import { RootState } from "store/store"

import { createSelector, createSlice } from "@reduxjs/toolkit"

export const initialState: IInitialStateMachines = {
  machinesCollection: null,
  machineSelected: null,
  machinesBarChartData: [],
  machinesStatusHistoryData: null,
  machineStatusHistoryDuration: "1_week",
  machinesCollectionAcquisitionDate: null,
  machinesCollectionDatatable: null,
  // TO DO this should probably be moved to the global slice
  selectedTemperatureTimespan: 24 * 60,
  selectedVibrationTimespan: 24 * 60,
  selectedHealthTimespan: 24 * 60,
  selectedMachineId: null,
}

type StatusMap<T extends string> = Record<number, T>
const statusMap: StatusMap<"normal" | "warning" | "critical"> = {
  0: "normal",
  1: "warning",
  2: "critical",
}

const machinesSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {
    setTemperatureTimespan: (state, action) => {
      state.selectedTemperatureTimespan = action.payload
    },
    setVibrationTimespan: (state, action) => {
      state.selectedVibrationTimespan = action.payload
    },

    setHealthTimespan: (state, action) => {
      state.selectedHealthTimespan = action.payload
    },
    setMachineBarChartData: (state, action) => {
      state.machinesBarChartData = action.payload
    },
    setMachineStatusHistoryDuration: (state, action) => {
      state.machineStatusHistoryDuration = action.payload
    },
    setMachinesCollectionDatatable: (state, action) => {
      state.machinesCollectionDatatable = action.payload
    },
    setMachinesCollection: (state, action) => {
      state.machinesCollection = action.payload
    },
    setMachinesCollectionAcquisitionDate: (state, action) => {
      state.machinesCollectionAcquisitionDate = action.payload
    },
    setSelectedMachine: (state, action) => {
      if (state.machineSelected === null) state.machineSelected = action.payload
      if (state.machineSelected?.machine_id !== action.payload.machine_id)
        state.machineSelected = action.payload
    },
    setSelectedMachineId: (state, action) => {
      state.selectedMachineId = action.payload
    },
  },
})
export const machinesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMachineCard: builder.query<Machine, IGenerateMachineCard>({
      query: ({ machineId }: IGenerateMachineCard) => ({
        api: DashboardAPI.generateMachineCard({
          machineId,
        }),
      }),
      keepUnusedDataFor: 5,
    }),
    getMachineDurationInState: builder.query<any, any>({
      query: ({ machineId = "" }: any) => ({
        api: MachinesAPI.getMachineDurationInState(machineId),
      }),
    }),
    // this is not used anywhere in the app, should we delete this?
    getMachineStatusHistoryData: builder.query<any, any>({
      query: ({
        siteUUID,
        duration_number,
        duration_type,
        machineId,
      }: IGenerateHistoryMachineStatus) => ({
        api: DashboardAPI.generateHistoryMachineStatus({
          siteUUID,
          duration_number,
          duration_type,
          machineId,
        }),
      }),
      keepUnusedDataFor: 5,
      transformResponse: (response: any, _, arg) => ({
        labels: response[`machine_critical_status_${selectDurationType()}`]
          .map((el: any) => getBarChartLabels(el))
          .reverse(),
        datasets: [
          {
            data: response[`machine_normal_status_${selectDurationType()}`]
              .map((el: any) => el.value_duration)
              .reverse(),
            backgroundColor: "green",
          },
          {
            data: response[`machine_warning_status_${selectDurationType()}`]
              .map((el: any) => el.value_duration)
              .reverse(),
            backgroundColor: "orange",
          },
          {
            data: response[`machine_critical_status_${selectDurationType()}`]
              .map((el: any) => el.value_duration)
              .reverse(),
            backgroundColor: "red",
          },
        ],
      }),
    }),
    getInformatonCard: builder.query<any, any>({
      query: ({ siteUUID }: any) => ({
        api: DashboardAPI.generateInformatonCard({
          siteUUID,
        }),
      }),
      keepUnusedDataFor: 5,
      transformResponse: (response: any) =>
        Object.entries(response).map((el) => {
          const matcher =
            el[0].match(/^active/) ||
            el[0].match(/^innactive/) ||
            el[0].match(/^warn/) ||
            el[0].match(/^critical/)
          if (matcher) {
            return { state: matcher[0], data: el[1] }
          }

          return null
        }),
    }),

    getMachineCardStatusInfoSupa: builder.query<any, any>({
      queryFn: async (arg) => {
        const { machineId } = arg

        const response = await MachinesAPI.getMachineDurationInStateSupa(
          machineId,
        )

        if (!!response.error) {
          return { error: response.error }
        }

        const machineHealth = response?.data[0].last_health_ui
        const state = statusMap[Number(response?.data[0]?.last_state_ui)]
        const duration = Math.floor(response?.data[0].time_diff_seconds)

        return {
          data: {
            machineHealth,
            state,
            duration,
          },
        }
      },
    }),

    getMachineBarStateDuration: builder.query<any, any>({
      queryFn: async (arg: any) => {
        const {
          machines,
          machineStatusHistoryDuration,
        }: {
          machines: MachineCollection[]
          machineStatusHistoryDuration: string
        } = arg

        const promises = machines.map((machine) => {
          const params = {
            duration: getBarGraphDurationLabel(machineStatusHistoryDuration),
            machine_id: machine.machine_id,
          }
          return {
            request: DashboardAPI.getMachineBarStateDuration(params),
            machine_id: machine.machine_id,
          }
        })

        const responses = await Promise.allSettled(
          promises.map((p) => p.request),
        )

        const results = responses.map((r, i) => {
          if (r.status === "fulfilled" && r.value) {
            return {
              machine_id: promises[i].machine_id,
              data: r.value.data,
            }
          }

          return {
            error: "error",
          }
        })
        return { data: results }
      },
    }),
  }),
})

const getAllSiteMachinesOptimized = (departments: DepartmentCollection[]) => {
  if (!departments || !departments.length) {
    /** Edge case 1 and 2:
     *  If no departments or empty array, return an empty array.
     * */
    return []
  }

  const allMachines: any[] = []

  /** Instead of a for loop a flat map could be used instead
   *  to help make the code more concise and potentially improve readability, */
  for (const department of departments) {
    const productionLines = department.production_line_collection

    if (!productionLines || !productionLines.length) {
      /**Edge case 3:
       * If no production lines in a department, skip to the next department.
       * */
      continue
    }

    if (productionLines.length === 1) {
      /**Edge case 4:
       * If there is only one production line in a department
       * add its machines to the result.
       * */
      const machines = productionLines[0].machine_collection
      if (machines) {
        allMachines.push(...machines)
      }
    } else {
      /**Edge case 5:
       * If there are multiple production lines in a department,
       * add all machines to the result.
       * */
      for (const productionLine of productionLines) {
        const machines = productionLine.machine_collection
        if (machines) {
          allMachines.push(...machines)
        }
      }
    }
  }

  return allMachines
}

const baseMachinesOverviewSelector = (
  state: RootState,
  machines: MachineCollection[],
): MachinesOverview => {
  // Early exit if no machines are available
  if (!machines.length) {
    return {
      machines: {
        key: "machines",
        value: [],
      },
      overview: [],
    }
  }

  // Extract necessary data from state
  const { organizationId } = state.organizations
  const { selectedSiteId } = state.sites

  // Check organization data and selected site
  const organizationData =
    organizationApiSlice.endpoints.getOrganization.select({
      uuid: organizationId,
    })(state).data

  if (organizationData === undefined || !selectedSiteId) {
    return {
      machines: {
        key: "machines",
        value: [],
      },
      overview: [],
    }
  }

  // Calculate machine state overview
  const totalMachines = machines.length
  let processedMachines = 0

  const machineStateOverview = machines.reduce(
    (acc: any, { machine_id: machineId }) => {
      const cachedMachineState =
        machinesApiSlice.endpoints.getMachineCardStatusInfoSupa.select({
          machineId,
        })(state)?.data

      if (cachedMachineState === undefined) {
        return acc
      }

      const currentStateCount = acc[cachedMachineState.state] || 0

      return {
        ...acc,
        [cachedMachineState.state]: currentStateCount + 1,
      }
    },
    { critical: 0, warning: 0, normal: 0, underTraining: 0 },
  )

  // Calculate the machine state count and generate the overview array
  const overview = Object.entries(machineStateOverview).map(([key, value]) => {
    processedMachines += Number(value)

    return {
      key,
      value:
        key === "underTraining" ? totalMachines - processedMachines : value,
    }
  })

  const result = {
    machines: {
      key: "machines",
      value: overview.filter((entry) => entry.value !== 0),
    },
    overview,
  }

  return result
}

export const selectSiteMachines = createSelector(
  [(state) => state.sites.selectedSiteId, (state, otherArgs) => otherArgs],
  (selectedSiteId, { site_collection: siteCollection }) => {
    if (siteCollection === undefined || siteCollection.length === 0) return []
    const { department_collection } =
      siteCollection.find((site: ISite) => site.site_id === selectedSiteId) ??
      []
    return getAllSiteMachinesOptimized(department_collection)
  },
)

export const selectMachinesOverview = createSelector(
  [(state: RootState, machines: MachineCollection[]) => ({ state, machines })],
  ({ state, machines }) => baseMachinesOverviewSelector(state, machines),
)

export const {
  useGetMachineCardQuery,
  useGetMachineStatusHistoryDataQuery,
  useGetMachineDurationInStateQuery,
  useGetMachineCardStatusInfoSupaQuery,
  useGetMachineBarStateDurationQuery,
} = machinesApiSlice
export const {
  setMachineStatusHistoryDuration,
  setMachinesCollectionDatatable,
  setMachineBarChartData,
  setMachinesCollection,
  setMachinesCollectionAcquisitionDate,
  setSelectedMachine,
  setSelectedMachineId,
  setTemperatureTimespan,
  setVibrationTimespan,
  setHealthTimespan,
} = machinesSlice.actions
export default machinesSlice.reducer
