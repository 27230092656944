interface FlatData {
  organisation_id: string
  site_id: string
  department_id: string
  production_line_id: string
  machine_id: string
  asset_id: string
}

interface TransformedData {
  organisation_id: string
  site_collection: {
    site_id: string
    department_collection: {
      department_id: string
      production_line_collection: {
        production_line_id: string
        machine_collection: {
          machine_id: string
          asset_collection: {
            asset_id: string
          }[]
        }[]
      }[]
    }[]
  }[]
}

export const transformOrganizationData = (
  flatData: FlatData[],
): TransformedData => {
  const result: TransformedData = {
    organisation_id: "",
    site_collection: [],
  }

  flatData.forEach((item) => {
    let site = result.site_collection.find((s) => s.site_id === item.site_id)
    if (!site) {
      site = { site_id: item.site_id, department_collection: [] }
      result.site_collection.push(site)
    }

    let department = site.department_collection.find(
      (d) => d.department_id === item.department_id,
    )
    if (!department) {
      department = {
        department_id: item.department_id,
        production_line_collection: [],
      }
      site.department_collection.push(department)
    }

    let productionLine = department.production_line_collection.find(
      (p) => p.production_line_id === item.production_line_id,
    )
    if (!productionLine) {
      productionLine = {
        production_line_id: item.production_line_id,
        machine_collection: [],
      }
      department.production_line_collection.push(productionLine)
    }

    let machine = productionLine.machine_collection.find(
      (m) => m.machine_id === item.machine_id,
    )
    if (!machine) {
      machine = { machine_id: item.machine_id, asset_collection: [] }
      productionLine.machine_collection.push(machine)
    }

    machine.asset_collection.push({ asset_id: item.asset_id })
  })

  if (flatData.length > 0) {
    result.organisation_id = flatData[0].organisation_id
  }

  return result
}
