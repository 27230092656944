import React from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import NoDataFound from "core/components/NoDataFound"
import { RootState } from "store/rootReducer"
import { ROW_HEIGHT } from "core/constants/ui"
import { useAppPostGetTimeseries } from "core/hooks/apiHooks"
import { useGetTempQuery } from "store/Assets/assetsSlice"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"

import { fontSpecs } from "../../helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import { reformAssetData } from "core/dataActions"
import { calculateGraphUpperOffsetValue } from "core/helpers"
import { minutesToMilliseconds } from "date-fns"
import { IDynamicGraphProps } from "types/dynamicGraph"

const labelsScaleFactor = 120

interface ISimpleTimeseriesGraph extends IDynamicGraphProps {
  requestParams: any
}

const SimpleTimeseriesGraph = ({
  requestParams,
  titleCode: title,
  iconCode,
  measureUnitCode: measureUnit,
  assetId: selectedAssetId,
  heightFactor,
  thresholds,
  tooltipText,
  refresh_interval: interval = 5,
}: ISimpleTimeseriesGraph) => {
  const { t } = useTranslation()

  const { globalSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [window.innerWidth])

  const theme = useTheme()

  const HEIGHT_OFFSET = 50

  const graphConfig = {
    scales: {
      x: {
        title: {
          display: false,
        },
        type: "time",

        ticks: {
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
        },
      },
      y: {
        ticks: {
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        title: {
          font: fontSpecs,
          text: measureUnit,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    spanGaps: 999999,
  } as ChartOptions<"line">

  const requestBody = {
    ...requestParams[0].requestBody,
    ...{
      start_time: new Date(globalSelectedRange.fromEpoch).toISOString(),
    },
  }

  const {
    data = [],
    isFetching,
    isError,
    refetch,
  } = useAppPostGetTimeseries(
    requestBody,
    // {
    //   table_name: "core_beat_test_table",
    //   symbols_names: ["corporation", "production_line", "machine"],
    //   symbols_values: ["CORP1", "PL1", "M1"],
    //   column_name: "value",
    //   timestamp_name: "ts",
    //   start_time: new Date(globalSelectedRange.fromEpoch * 1000).toISOString(),
    //   end_time: new Date(globalSelectedRange.toEpoch * 1000).toISOString(),
    // },
    {
      skip: !selectedAssetId,
      pollingInterval: minutesToMilliseconds(interval),
    },
  )

  const handleOnRetry = () => refetch()
  const lastIndex = data.length - 1

  const { latestValue } = useGetTempQuery(
    { selectedAssetId },
    {
      skip: data.length !== 0 && isFetching,
      selectFromResult: ({ data }) => ({ latestValue: data?.values[0]?.value }),
    },
  )

  const height = ROW_HEIGHT * heightFactor

  const max =
    data.length !== 0 ? Math.max(...data.map((item: any) => item.value)) : 0

  const hasThresholds = !!thresholds

  const upperBoundValue = hasThresholds
    ? calculateGraphUpperOffsetValue(max, thresholds)
    : 0

  if (data.length === 0) {
    return (
      <BaseCard
        sx={styles.baseCard}
        customHeader={
          <GraphBaseHeader
            title={t(title)}
            iconCode={iconCode}
            tooltipText={tooltipText}
          />
        }
      >
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          onRetry={handleOnRetry}
        >
          <NoDataFound
            style={{
              height: height - 30,
            }}
          />
        </AppCardLoader>
      </BaseCard>
    )
  }

  return (
    <BaseCard
      sx={{ ...styles.baseCard, height }}
      customHeader={
        <GraphBaseHeader
          title={title}
          iconCode={iconCode}
          tooltipText={tooltipText}
        />
      }
    >
      <Box sx={{ ...styles.graphContainer, height: height - HEIGHT_OFFSET }}>
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRetry={() => {}}
        >
          <LineChart
            datasetLabel={title}
            unit={measureUnit}
            zoomEnabled={false}
            config={graphConfig}
            upperBoundOffset={upperBoundValue}
            lineColor={theme.palette.primary.main}
            graphData={reformAssetData(data)}
            thresholds={thresholds}
          />
        </AppCardLoader>
      </Box>
    </BaseCard>
  )
}

export default SimpleTimeseriesGraph
