import { AlertDataType } from "screens/Alerts/subcomponents/AlertsTab";
import { apiSlice } from "store/queryApi";
import { supabase } from "core/api/supabase";

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  alerts: [],
} as any

export type AlertsResponse = AlertDataType[]

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlerts: (state, action) => {
      state.alerts = action.payload
    },
  },
})

const AUTOMATED_RESOLVE_BY_ACCOUNT = "alert-python-cron@core-innovation.com"

const SECONDS_TO_KEEP_DATA = 60

export const alertsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query<AlertsResponse, any>({
      keepUnusedDataFor: SECONDS_TO_KEEP_DATA,
      query: ({ organizationId }) => ({
        api: supabase
          .from("alerts")
          .select("*")
          .neq("alerts_resolved_by", AUTOMATED_RESOLVE_BY_ACCOUNT)
          .eq("alerts_organisation_id", organizationId),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ alerts_id }: any) =>
                  ({ type: "Alerts", alerts_id } as const),
              ),
              { type: "Alerts", id: "LIST" },
            ]
          : [{ type: "Alerts", id: "LIST" }],
    }),
    resolveAlert: builder.mutation({
      query: ({ alerts_id, username }) => ({
        api: supabase
          .from("alerts")
          .update({ alerts_resolved: true, alerts_resolved_by: username })
          .eq("alerts_id", alerts_id)
          .select(),
      }),

      invalidatesTags: (result, error, alerts_id) => [
        { type: "Alerts", alerts_id },
      ],
    }),
  }),
})

export const { useGetAlertsQuery, useResolveAlertMutation } = alertsApiSlice

export const { setAlerts } = alertsSlice.actions

export default alertsSlice.reducer
