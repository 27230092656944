import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  isLoading: false,
  thresholdError: { value: false, message: "" },
  hasNotification: false,
  configuration: null,
}

const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setThresholdError: (state, action) => {
      state.thresholdError = action.payload
    },
    setDynamicUI: (state, action) => {
      state.configuration = action.payload
    },
    setHasNotification: (state, action) => {
      state.hasNotification = action.payload
    },
  },
})

export const {
  setIsLoading,
  setThresholdError,
  setDynamicUI,
  setHasNotification,
} = UISlice.actions

export default UISlice.reducer
