import React from "react"

import AppTypography from "core/components/AppTypography"
import { capitalizeFistLetterOfEveryWord } from "core/helpers"
import { RootState } from "store/rootReducer"
import { setAssetAxisSelected } from "store/Assets/assetsSlice"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"

import { styles } from "./styles"

const Selector = ({ label, selected, value, sx }: any) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(setAssetAxisSelected(value))
  }
  return (
    <Box onClick={onClick}>
      <AppTypography
        textTransform="capitalize"
        sx={{
          ...(selected ? styles.axisLabelSelected : styles.axisLabel),
          ...sx,
        }}
      >
        {label}
      </AppTypography>
    </Box>
  )
}

const AxisSelector = () => {
  const { assetAxisSelected } = useSelector((state: RootState) => state.assets)
  const { t } = useTranslation()

  return (
    <Box sx={styles.axisContainer}>
      <Selector
        value="x"
        label={t("axis.x.text")}
        selected={assetAxisSelected === "x"}
      />
      <Selector
        value="y"
        label={t("axis.y.text")}
        selected={assetAxisSelected === "y"}
      />
      <Selector
        sx={{ borderRight: "none" }}
        value="z"
        label={t("axis.z.text")}
        selected={assetAxisSelected === "z"}
      />
    </Box>
  )
}

export default AxisSelector
