import { BORDER_RADIUS } from "../AlertRow/styles"
import { ROW_HEIGHT } from "../AlertsTab/styles"

export const styles = {
  root: {
    paddingRight: "16px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    height: ROW_HEIGHT,
    borderRadius: `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0`,
  },
  button: {
    height: ROW_HEIGHT / 2,
    fontSize: "12px",
    color: "white",
    lineHeight: "normal",
  },
}
