import * as React from "react"

import { pushNavigationHistory, setSelectedView } from "store/rootSlice"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../../../store/rootReducer"
import { store } from "store/store"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

let hasBackButtonEvent = false

const Navigator = () => {
  const selectState = (state: RootState) => state.global
  const { selectedView } = useSelector(selectState)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const navigateTo = async (view: string) => {
    const history = store.getState().global.navigationHistory
    const lastView = history[history.length - 1]

    // await dispatch(setSelectedView(view))

    if (lastView !== view) {
      // await dispatch(pushNavigationHistory(view))
    }

    // navigate(`/${view}`, { replace: false })
  }

  const handleHWBack = async () => {
    const history = store.getState().global.navigationHistory

    const reducedHistory = history.slice(0, -1)
    const prevView = reducedHistory[reducedHistory.length - 1]

    // await dispatch(updateNavigationHistory(reducedHistory))
    // await dispatch(setSelectedView(prevView))

    // navigate(`/${prevView}`, { replace: false })
  }

  React.useEffect(() => {
    if (hasBackButtonEvent) return

    window.addEventListener("popstate", handleHWBack)
    hasBackButtonEvent = true
    return () => {
      window.removeEventListener("popstate", handleHWBack)
      hasBackButtonEvent = false
    }
  }, [])

  useEffect(() => {
    navigateTo(selectedView)
  }, [selectedView])

  return <></>
}
export default Navigator
