import React, { useEffect } from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import {
  capitalizeFistLetter,
  getDurationInState,
  getDurationInStateLabel,
} from "core/helpers"
import ConditionStatusInformation from "core/components/AppCard/MachineCard/subcomponents/ConditionStatusInformation"
import { RootState } from "store/rootReducer"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined"
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined"

import { styles } from "./styles"
import { minutesToMilliseconds } from "date-fns"

import StatusIcon from "core/components/StatusIcon"
import { useGetMachineCardStatusInfoSupaQuery } from "store/Machines/machinesSlice"
import { DEMO_RIG_MACHINE_ID, renderFlags } from "core/constants/ui"

const MachineInfo = () => {
  const { t } = useTranslation()

  const machinesState = (state: RootState) => state.machines
  const { machineSelected } = useSelector(machinesState)
  const { machine_id: machineId = "", asset_collection: availableAssets = [] } =
    machineSelected

  const selectStateSite = (state: RootState) => state.sites
  const { selectedSiteId: siteUUID } = useSelector(selectStateSite)

  const {
    data,
    fulfilledTimeStamp: timestamp,
    isError,
    isFetching,
    refetch,
  } = useGetMachineCardStatusInfoSupaQuery(
    {
      machineId,
    },
    {
      skip: !machineId && !siteUUID,
      pollingInterval: minutesToMilliseconds(60),
    },
  )

  const {
    state: machineStatus = "",
    duration: machineTimeInCurrentStatus = 0,
    machineHealth: health = 0,
  } = data || {}

  const durationInStateLabel = getDurationInStateLabel(
    machineTimeInCurrentStatus,
  )
  const durationInState = getDurationInState(machineTimeInCurrentStatus)

  const assetsPerMachine = availableAssets.length

  const healthScoreLabel = health < 10 ? "<10%" : `${health}%`

  useEffect(() => {
    if (DEMO_RIG_MACHINE_ID !== machineId) return

    const intervalId = setInterval(() => {
      renderFlags.isFirstRenderMachineInfo = false
      refetch()
    }, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <BaseCard
      title={t("machine.info.text")}
      lastUpdate={timestamp}
      sx={styles.baseCard}
    >
      <AppCardLoader
        isLoading={renderFlags.isFirstRenderMachineInfo && isFetching}
        hasError={isError}
        onRetry={refetch}
        style={{
          minHeight: 120,
        }}
      >
        <Box sx={styles.boxContainer}>
          <ConditionStatusInformation
            sx={styles.infoBox}
            title={t("machine.state.text")}
            icon={<StatusIcon state={machineStatus} />}
            info={capitalizeFistLetter(machineStatus.toLowerCase())}
            status={machineStatus}
          />
          <ConditionStatusInformation
            sx={styles.infoBox}
            title={t("duration.in.current.state.text")}
            icon={<TimelapseOutlinedIcon />}
            info={`${durationInState} ${t(durationInStateLabel)}`}
          />
          <ConditionStatusInformation
            sx={styles.infoBox}
            title={t("health.score.text")}
            icon={<MonitorHeartOutlinedIcon />}
            info={healthScoreLabel}
            status={machineStatus}
          />
          <ConditionStatusInformation
            sx={styles.infoBox}
            title={t("assets.text")}
            icon={<Inventory2OutlinedIcon />}
            info={assetsPerMachine}
          />
        </Box>
      </AppCardLoader>
    </BaseCard>
  )
}

export default MachineInfo
