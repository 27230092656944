import "chartjs-adapter-date-fns"

import React from "react"

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js"
import { Line } from "react-chartjs-2"
import zoomPlugin from "chartjs-plugin-zoom"

import { useTheme } from "@mui/material"

import { lineColoring, thresholdLine, zoomOptions } from "./chartHelpers"

ChartJS.register(
  CategoryScale,
  Filler,
  zoomPlugin,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeSeriesScale,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale,
)

interface ILineChartProps {
  graphData: any[]
  thresholds?: any
  config?: ChartOptions<"line">
  lineColor?: string
  unit?: string
  zoomEnabled?: boolean
  upperBoundOffset?: number | null
  axisLabels?: any
  datasetLabel?: string | null
  multiLines?: boolean
}

const LineChart = ({
  graphData,
  datasetLabel = "",
  thresholds = {},
  config,
  lineColor,
  unit,
  zoomEnabled = true,
  upperBoundOffset = 0,
  axisLabels = { yLabel: "", xLabel: "" },
  multiLines = false,
}: ILineChartProps) => {
  const theme = useTheme()
  const chartRef = React.useRef(null)
  const hasThresholds = !!thresholds && Object.values(thresholds).length
  const warnThreshold = !!hasThresholds ? thresholds.warning : 0
  const critThreshold = !!hasThresholds ? thresholds.critical : 0

  const hasConfig = !!config && !!Object.keys(config).length

  const data = {
    datasets: !multiLines
      ? [
          {
            data: graphData,
            label: datasetLabel,
            borderWidth: 1,
            radius: 0,

            borderColor: !!lineColor
              ? lineColor
              : (context: any) =>
                  lineColoring(context, warnThreshold, critThreshold),
            drawActiveElementsOnTop: true,
          },
        ]
      : graphData,
  } as any

  const options = {
    layout: {
      autoPadding: true,
    },
    responsive: true,
    spanGaps: true,
    maintainAspectRatio: false,
    animation: false,
    normalized: true,
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },

    scales: {
      x: {
        grid: { color: theme.palette.graphLines.main },
        title: {
          font: { size: 10 },
          text: axisLabels.xLabel,
          display: true,
        },
        type: "timeseries",

        ticks: {
          autoSkip: true,
          color: theme.palette.text.primary,
          minRotation: 0,
          maxRotation: 0,
          font: { size: 10 },
        },
      },
      y: {
        grid: { color: theme.palette.graphLines.main },
        max: hasThresholds ? upperBoundOffset : null,
        bounds: "ticks",
        ticks: {
          color: theme.palette.text.primary,
          font: { size: 10 },
        },
        title: {
          font: {
            family: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            size: 12,
          },
          text: axisLabels.yLabel,
          display: true,
        },
      },
    },
    transitions: { zoom: { animation: { duration: 0 } } },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: "min-max",
      },
      ...zoomOptions,
      legend: {
        display: false,
      },
    },
  } as ChartOptions<"line">

  const isObject = (o: any) => o && typeof o === "object",
    updateChartOptions = (a: any, b: any) => {
      Object.entries(b).forEach(
        ([k, v]) =>
          (a[k] = isObject(v) ? updateChartOptions(a[k] || {}, v) : v),
      )
      return a
    },
    result =
      config !== undefined ? updateChartOptions(options, config || {}) : options

  const userConfig = hasConfig ? result : options

  userConfig!.plugins!.zoom!.zoom!.wheel!.enabled = zoomEnabled
  userConfig!.plugins!.zoom!.zoom!.drag!.enabled = zoomEnabled

  const plugins = [
    {
      id: "threshold",
      afterDatasetDraw: (chart: any, _options: any) => {
        thresholdLine(chart, _options, "warning", warnThreshold)
        thresholdLine(chart, _options, "critical", critThreshold)
      },
    },
  ] as any

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line
        redraw
        ref={chartRef}
        data={data}
        options={userConfig}
        plugins={plugins}
      />
    </div>
  )
}

export default LineChart
