export const styles = {
  baseCard: {
    boxShadow: 0,
    width: "100%",
    m: 1,
pt: 1,
  },
  graphContainer: {
    ml: 1,
    mt: 2,
    p: 1,

    boxShadow: 0,
  },
}
