import React from "react"

import { RootState } from "store/rootReducer"
import ScrollTop from "core/components/ScrollToTop"
import { setSelectedMachine } from "store/Machines/machinesSlice"
import { useDispatch, useSelector } from "react-redux"

import { Box, Grid, Typography } from "@mui/material"

import ComponentSelector from "./subcomponents/ComponentSelector"
import Subheader from "./subcomponents/Subheader"
import { MachineNotFoundForDynamicUI } from "./subcomponents/MachineNotFoundForDynamicUI"
import SelectMachine from "./subcomponents/MachineSelect"

const DynamicMachineDetailScreen = () => {
  const dispatch = useDispatch()

  const selectedMachineId = useSelector((state: RootState) => {
    const { machine_id } = state.machines.machineSelected ?? ""

    if (!!machine_id) {
      return machine_id
    } else {
      const selectedMachine =
        state.organizations.organizationSelected.site_collection[0]
          .department_collection[0].production_line_collection[0]
          .machine_collection[0]

      dispatch(setSelectedMachine(selectedMachine))
      return selectedMachine.machine_id
    }
  })

  const config = useSelector((state: RootState) => state.ui.configuration)

  const isMachineFound = config.machineDetailScreen.machines.some(
    (machine: any) => machine.id === selectedMachineId,
  )

  let selectedRows = []

  if (isMachineFound) {
    //@ts-ignore
    const { rows } = config.machineDetailScreen.machines.filter(
      (machine: any) => machine.id === selectedMachineId,
    )[0]

    selectedRows = rows
  }

  let index = 1

  const selectState = (state: RootState) => state.global
  const { isDrawerOpen } = useSelector(selectState)

  return !isMachineFound ? (
    <>
      <SelectMachine />
      <MachineNotFoundForDynamicUI selectedMachine={selectedMachineId} />
    </>
  ) : (
    <>
      <Subheader />
      <Grid
        sx={{
          width: "calc(99%)",
        }}
        container
        spacing={1}
      >
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid container spacing={1}>
              {selectedRows.map((row: any) => {
                const rowTitle = row.columns[0].infoCode ?? ""

                return (
                  <Box
                    sx={{
                      display: "flex",
                      width: isDrawerOpen ? "91vw" : "94vw",
                      flexDirection: "column",
                    }}
                    key={index++}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        pl: 6,
                        textAlign: "center",
                      }}
                    >
                      {rowTitle}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={index++}
                    >
                      {row.columns.map((column: any) => {
                        const dimension = 12 / row.columns.length
                        return (
                          <ComponentSelector
                            key={index++}
                            row={row}
                            column={column}
                            dimension={dimension}
                          />
                        )
                      })}
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ScrollTop />
    </>
  )
}

export default DynamicMachineDetailScreen
