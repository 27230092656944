import React from "react"

import BarChart from "core/components/Plots/BarPlot"

import { selectSiteMachines } from "store/Machines/machinesSlice"
import { useSelector } from "react-redux"

import { styles } from "./styles"

import MachineHealthHeader from "./subcomponents/MachineHealthHeader"
import { MachineCollection } from "models/organization"
import { RootState } from "store/store"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"
import { useGetOrganizationBarStateDurationTestQuery } from "store/Organisation/organizationSlice"

const ChartJsBarChart = () => {
  const selectOrganizationState = (state: RootState) => state.organizations

  const { machineStatusHistoryDuration } = useSelector(
    (state: RootState) => state.machines,
  )
  const { organizationId } = useSelector(selectOrganizationState)
  const { organizationData } = useAppGetOrganizationQuery(organizationId)

  const machines: MachineCollection[] =
    useSelector((state: RootState) =>
      selectSiteMachines(state, { ...organizationData }),
    ) || []

  const lookup = {
    "1_week": "week",
    "1_month": "month",
    "12_month": "year",
  }

  const duration = lookup[machineStatusHistoryDuration]

  const { data, isFetching, isError, refetch } =
    useGetOrganizationBarStateDurationTestQuery(
      {
        organizationId,
        duration,
      },
      {
        skip: machines?.length === 0,
      },
    )

  const graphData = data?.data ?? []

  return (
    <AppGraphWrapper
      baseCardStyle={styles.card}
      graphContainerStyle={styles.boxChartContainer}
      restHeaderProps={{ customHeader: <MachineHealthHeader /> }}
      isLoading={isFetching}
      hasError={isError}
      onRetry={refetch}
    >
      <BarChart data={graphData} />
    </AppGraphWrapper>
  )
}

export default ChartJsBarChart
