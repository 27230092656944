import React, { useEffect, useMemo, useState } from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js"
import { Line } from "react-chartjs-2"
import { RootState, store } from "store/store"
import StatusIcon from "core/components/StatusIcon"
import { useDispatch, useSelector } from "react-redux"

import { Box, useTheme } from "@mui/material"

import {
  calculateDaysFromMinutes,
  getHealthBarGraphDecimationForDemoRig,
  interpolateGraphData,
  interpolateGraphDataForDemoMachine,
  transformHealthHistoryData,
  transformHealthHistoryDataForDemoMachine,
} from "./helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import { getTooltip } from "core/helpers"
import { differenceInCalendarDays } from "date-fns"

import { usePostGetHealthStateGraphQuery } from "store/Assets/assetsSlice"
import { setHealthTimespan } from "store/Machines/machinesSlice"
import { DEMO_RIG_MACHINE_ID, renderFlags } from "core/constants/ui"

ChartJS.register(
  CategoryScale,
  Filler,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const MachineHealthChart = () => {
  const chartRef = React.useRef(null)

  const CHART_LINE_WIDTH = 10

  const { machineSelected } = useSelector((state: RootState) => state.machines)

  const { healthGraphSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const labelsScaleFactor = 180

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] = useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )

  const { healthGraphUnit } = useSelector((state: RootState) => state.global)

  const isDemoMachine = machineSelected?.machine_id === DEMO_RIG_MACHINE_ID

  const {
    data: healthData = [],
    isError,
    isFetching,
  } = usePostGetHealthStateGraphQuery({
    start_time: healthGraphSelectedRange.fromEpoch,
    end_time: healthGraphSelectedRange.toEpoch,
    machine_id: machineSelected?.machine_id,
  })

  const interpolationArguments = {
    startDate: healthGraphSelectedRange.fromEpoch,
    endDate: healthGraphSelectedRange.toEpoch,
    dataObject: healthData.map((item: any) => ({
      date: item.x / 1000,
      value: item.y,
    })),
  }

  const sanitizedData = isDemoMachine
    ? interpolateGraphDataForDemoMachine(interpolationArguments)
    : interpolateGraphData(interpolationArguments)

  const getDaysDifference = () => {
    const start = new Date(healthGraphSelectedRange.fromEpoch)
    const end = new Date(healthGraphSelectedRange.toEpoch)

    return differenceInCalendarDays(end, start)
  }

  const transformHealthHistoryDataArgs = {
    rawData: sanitizedData.data.map((item: any) => {
      const timeInSeconds =
        typeof item.date === "string"
          ? new Date(item.date).getTime() / 1000
          : item.date

      return {
        date: timeInSeconds,
        value: item.value,
      }
    }),
    daysDifference: getDaysDifference(),
    timeSelector: healthGraphSelectedRange,
  }

  const transformedData = isDemoMachine
    ? transformHealthHistoryDataForDemoMachine(transformHealthHistoryDataArgs)
    : transformHealthHistoryData(transformHealthHistoryDataArgs)

  const theme = useTheme()

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: getTooltip(transformedData.epoch) as any,
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        top: CHART_LINE_WIDTH / 2,
      },
    },
    scales: {
      y: {
        max: 3,
        ticks: {
          max: 5,
          display: false,
          font: {
            family: "FontAwesome",
          },
          stepSize: 1,
          count: 4,
        },

        beginAtZero: true,
      },
      x: {
        time: {
          unit: healthGraphUnit,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: maxTicksGraphLabels,
          maxRotation: 0,
        },
        beginAtZero: true,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
  } as ChartOptions<"line">

  const data = useMemo(
    () => ({
      labels: transformedData.labels,
      datasets: [
        {
          label: "normal",
          data: transformedData.normal,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: theme.palette.success.light50,
        },
        {
          label: "warning",
          data: transformedData.warning,
          fill: false,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: theme.palette.warning.light50,
        },
        {
          label: "critical",
          clip: 10,
          data: transformedData.critical,
          borderWidth: CHART_LINE_WIDTH,
          fill: false,
          borderColor: theme.palette.error.light50,
        },
        {
          label: "idle",
          data: transformedData.idle,
          clip: 10,
          fill: false,
          borderWidth: CHART_LINE_WIDTH,
          borderColor: "rgba(128, 128, 128, 0.5)",
        },
      ],
    }),
    [transformedData],
  )

  const getTotalDaysPerType = (_data: any) => {
    const dateDifference = getDaysDifference()
    const normal = _data?.datasets[0].data.filter(
      (el: any) => el !== null,
    ).length
    const warning = _data?.datasets[1].data.filter(
      (el: any) => el !== null,
    ).length
    const critical = _data?.datasets[2].data.filter(
      (el: any) => el !== null,
    ).length
    const idle = _data?.datasets[3].data.filter((el: any) => el !== null).length

    const demoMachineDecimation = getHealthBarGraphDecimationForDemoRig({
      start: healthGraphSelectedRange.fromEpoch,
      end: healthGraphSelectedRange.toEpoch,
    })

    const dateRange = differenceInCalendarDays(
      new Date(healthGraphSelectedRange.toEpoch),
      new Date(healthGraphSelectedRange.fromEpoch),
    )

    const multiplier = isDemoMachine ? demoMachineDecimation / dateRange : 1

    return {
      normal: calculateDaysFromMinutes(
        _data?.datasets[0].data.filter((el: any) => el !== null).length *
          multiplier,
        dateDifference,
      ),
      warning: calculateDaysFromMinutes(
        _data?.datasets[1].data.filter((el: any) => el !== null).length *
          multiplier,
        dateDifference,
      ),
      critical: calculateDaysFromMinutes(
        _data?.datasets[2].data.filter((el: any) => el !== null).length *
          multiplier,
        dateDifference,
      ),
      idle: calculateDaysFromMinutes(
        _data?.datasets[3].data.filter((el: any) => el !== null).length *
          multiplier,
        dateDifference,
      ),
    }
  }

  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [[window.innerWidth]])

  const dispatch = useDispatch()

  const refetchData = () => {
    const currentRange = store.getState().global.healthGraphSelectedRange

    renderFlags.isFirstRenderHealth = false
    const timeDifference =
      new Date().getTime() - new Date(currentRange.toEpoch).getTime()

    const payload = {
      fromEpoch: new Date(
        new Date(currentRange.fromEpoch).getTime() + timeDifference,
      ).toISOString(),
      toEpoch: new Date(
        new Date(currentRange.toEpoch).getTime() + timeDifference,
      ).toISOString(),
    }

    dispatch(setHealthTimespan(payload))
  }

  useEffect(() => {
    if (DEMO_RIG_MACHINE_ID !== machineSelected?.machine_id) return

    const intervalId = setInterval(() => {
      refetchData()
    }, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <BaseCard
      sx={{ boxShadow: 0, my: 1, p: 1, height: 312 }}
      customHeader={
        <GraphBaseHeader
          isDemoMachine={isDemoMachine}
          daysPerType={getTotalDaysPerType(data)}
        />
      }
    >
      <AppCardLoader
        isLoading={renderFlags.isFirstRenderHealth && isFetching}
        hasError={isError}
        onRetry={() => {
          console.log("retry")
        }}
      >
        <Box sx={styles.iconsGraphContainer}>
          <Box sx={styles.iconsContainer}>
            <StatusIcon state="critical" />
            <StatusIcon state="warning" />
            <StatusIcon state="normal" />
            <StatusIcon state="idle" />
          </Box>
          <Box sx={styles.graphContainer}>
            <Line role="figure" ref={chartRef} options={options} data={data} />
          </Box>
        </Box>
      </AppCardLoader>
    </BaseCard>
  )
}

export default MachineHealthChart
